import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTransactionsMetadata } from '../../Hooks/useTransactionsMetadata'
import { useMultiSig } from '../../Hooks/useMultiSig'
import { Auth } from '../../State/Auth'
import { MultiSig } from '../../State/MultiSig'
import Clock from '../../Utils/Clock'
import { EventPipe } from '../../Utils/EventPipe'
import Config from '../../Config'
import { useOfflineContracts } from '../../Hooks/useOfflineContracts'
import { PendingTrxs } from '../../State/PendingTrxs'

export const Updater = () => {
  const { adapter, department } = useRecoilValue(Auth)
  const { getPendingTrxs } = useOfflineContracts()
  const { getHideableTransactions } = useTransactionsMetadata()
  const [, setMultiSigState] = useRecoilState(MultiSig)
  const [, setPendingTrxs] = useRecoilState(PendingTrxs)
  const { getOwners, getConfirmationsNumber, getTransactions } = useMultiSig()

  useEffect(() => {
    Clock.start()
    getOwners().then((owners) => setMultiSigState((st) => ({ ...st, owners })))
    getConfirmationsNumber().then((numConfirmations) =>
      setMultiSigState((st) => ({ ...st, numConfirmations }))
    )
    getTransactions()
      .then((trxs) =>
        getHideableTransactions(
          adapter!.blockchainConfig.blockchain,
          Config.contracts[department!]![adapter!.blockchainConfig.blockchain]!,
          trxs
        )
      )
      .then((transactions) => setMultiSigState((st) => ({ ...st, transactions })))
    getPendingTrxs().then(setPendingTrxs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter, getHideableTransactions])

  useEffect(() => {
    const fn = () => EventPipe.emit('REFRESH')
    Clock.on('TEN_SECONDS', fn)
    return () => {
      Clock.off('TEN_SECONDS', fn)
    }
  }, [])

  useEffect(() => {
    const fn = () => getOwners().then((owners) => setMultiSigState((st) => ({ ...st, owners })))
    EventPipe.on('REFRESH', fn)
    return () => {
      EventPipe.off('REFRESH', fn)
    }
  }, [getOwners, setMultiSigState])

  useEffect(() => {
    const fn = () =>
      getConfirmationsNumber().then((numConfirmations) =>
        setMultiSigState((st) => ({ ...st, numConfirmations }))
      )
    EventPipe.on('REFRESH', fn)
    return () => {
      EventPipe.off('REFRESH', fn)
    }
  }, [getConfirmationsNumber, setMultiSigState])

  useEffect(() => {
    const fn = () =>
      getTransactions()
        .then((trxs) =>
          getHideableTransactions(
            adapter!.blockchainConfig.blockchain,
            Config.contracts[department!]![adapter!.blockchainConfig.blockchain]!,
            trxs
          )
        )
        .then((transactions) => setMultiSigState((st) => ({ ...st, transactions })))
    EventPipe.on('REFRESH', fn)
    return () => {
      EventPipe.off('REFRESH', fn)
    }
  }, [getTransactions, setMultiSigState, getHideableTransactions, adapter, department])

  return <></>
}
