import {
  blockchainConfigMap,
  Blockchains,
  EthChainIds,
  getBlockchainConnectorByName,
  WalletConnectors
} from '@unifiprotocol/core-sdk'
import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Config from '../Config'
import { abi as MultiSignAbi } from '../Contracts/ABI/MultiSigWallet.json'
import { GetOwners } from '../Contracts/MultiSig/getOwners'
import { Auth } from '../State/Auth'
import { isValidSignature } from '../Utils/Crypto'

export const useAuth = () => {
  const { department } = useRecoilValue(Auth)
  const setAuthState = useSetRecoilState(Auth)

  const connect = useCallback(
    async (blockchain: Blockchains) => {
      if (!department) throw new Error('No department has been set up')
      const newConnector = getBlockchainConnectorByName(blockchain, WalletConnectors.Metamask)
      const { adapter: newAdapter } = await newConnector.connect()

      const multiSigAddress = Config.contracts[department]![blockchain]!
      await newAdapter.initializeContract(multiSigAddress, MultiSignAbi)

      const onNetworkChanged = async (chainId: EthChainIds) => {
        const blockchain = Object.values(blockchainConfigMap).find(
          (info) => info.chainId === chainId
        )?.blockchain
        if (!blockchain) throw new Error('Network not supported.')

        connect(blockchain)
      }

      const onAddressChanged = async () => {
        connect(blockchain)
      }

      newConnector.on('NetworkChanged', (chainId) => {
        newConnector.disconnect()
        newConnector.off('NetworkChanged', onNetworkChanged)
        onNetworkChanged(chainId)
      })
      newConnector.on('AddressChanged', () => {
        newConnector.disconnect()
        newConnector.off('AddressChanged', onAddressChanged)
        onAddressChanged()
      })

      const { value: owners } = await new GetOwners({
        contractAddress: multiSigAddress,
        params: []
      }).execute(newAdapter)

      if (!owners.find((o) => o.toLowerCase() === newAdapter.getAddress().toLowerCase())) {
        throw Error('Connected address is not an owner')
      }

      const msgSigned = await newAdapter.getProvider().getSigner().signMessage(Config.logInMessage)

      const isValid = isValidSignature(Config.logInMessage, msgSigned, newAdapter.getAddress())
      if (isValid) {
        setAuthState({
          adapter: newAdapter,
          department
        })
      } else {
        throw Error('Invalid signature')
      }
    },
    [setAuthState, department]
  )
  return { connect }
}
