import { PrimaryButton } from '@unifiprotocol/uikit'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { Auth } from '../../State/Auth'
import { RefreshBar } from '../RefreshBar'

const Body = styled.div`
  margin-top: 5.5rem;
  display: flex;
  justify-content: center;
  position: relative;

  ${(props) => props.theme.mediaQueries.xs} {
    margin-top: 9rem;
  }
`

const Aligned = styled.div`
  margin-top: 1rem;
  max-width: 970px;
  width: 100%;
`

const MarginedButton = styled(PrimaryButton)`
  margin-bottom: -2.5rem;
`

export const BodyWrapper: React.FC<{ refreshBar?: boolean }> = ({
  children,
  refreshBar = true
}) => {
  const navigate = useNavigate()
  const [, setAuthState] = useRecoilState(Auth)
  const goToHome = useCallback(() => {
    setAuthState({})
    navigate('/')
  }, [setAuthState, navigate])
  return (
    <Body>
      <Aligned>
        <MarginedButton onClick={goToHome}>Change multisig contract</MarginedButton>
        {refreshBar && <RefreshBar />}
        {children}
      </Aligned>
    </Body>
  )
}
