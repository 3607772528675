import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { toHex } from '@unifiprotocol/utils'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

export class ExecuteTransaction extends ContractUseCase<
  MultiSigContractMethods,
  number,
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<number>) {
    super(contractAddress, MultiSigContractMethods.executeTransaction, params!, false)
  }

  getArgs() {
    return [toHex(this.params)]
  }
}
