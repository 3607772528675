import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

export class RemoveOwner extends ContractUseCase<
  MultiSigContractMethods,
  string[],
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<{ owner: string }>) {
    super(
      contractAddress,
      MultiSigContractMethods.removeOwner,
      params?.owner ? [params?.owner] : [],
      true
    )
  }

  getArgs() {
    return this.params
  }
}
