import { atom } from 'recoil'
import { MultiSigTransaction } from '../Contracts/MultiSig/Types'

type TMultiSig = {
  owners: string[]
  numConfirmations: number
  transactions: MultiSigTransaction[]
}

export const MultiSig = atom<TMultiSig>({
  key: 'multiSigState',
  dangerouslyAllowMutability: true,
  default: { owners: [], numConfirmations: 0, transactions: [] }
})
