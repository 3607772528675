import { Blockchains } from '@unifiprotocol/core-sdk'

type TConfig = {
  logInMessage: string
  contracts: {
    [K in string]?: { [K in Blockchains]?: string }
  }
}

const Config: TConfig = {
  logInMessage:
    'One MultiSigWallet to rule them all, One MultiSigWallet to find them, One MultiSigWallet to bring them all, and in the darkness bind them.',
  contracts: {
    Admin: {
      [Blockchains.BinanceTestnet]: '0xa1281A6C38D9f3fb862Eca1406A0f7FfecDe1212',
      [Blockchains.EthereumGoerli]: '0x20C1c9c3DF2BFC0fFeA4c3381eB85E3DB10d717f',
      [Blockchains.FTM]: '0x9eE36f0f6183595458252FB03dF799f4FE45C019',
      [Blockchains.Binance]: '0x92a661d868Aa1c8a3072765B462480029DcD8582',
      [Blockchains.Iotex]: '0x97Ac65A70A804B6c35b408d9bD7B18fc52F4aDF0',
      [Blockchains.Harmony]: '0x814D299c9081085C6b99208f1387738EeD3D638F',
      [Blockchains.Polygon]: '0xbCE8163f572cECF5eAA939a75Ba9537501d30E0b',
      [Blockchains.Avalanche]: '0xddce1C777D4A9128c19d2373f5B7015797de8641',
      [Blockchains.Ethereum]: '0x59026F4168fb26a0bFc8C5155C9B59d0DF2fD001',
      [Blockchains.Ontology]: '0xAB63B0f4e1cDAad7c4849f8E9AEdcD40601C15d7',
      [Blockchains.BTTC]: '0xC814444DecA35e56c59b51C858c26213d6977F90'
    },
    Tech: {
      [Blockchains.BinanceTestnet]: '0x1FbB085e4e6c867CE7436B307dD0BcaA4F0A4178',
      [Blockchains.Harmony]: '0xb6f988ddf7F3eF18546FD277b8038AcB402FAA77',
      [Blockchains.Binance]: '0x95A501d34B271ab13D34F200bb1F2D7c7F209b64',
      [Blockchains.Iotex]: '0xd70E356d87E1960c65d8aCeC6e71608e8775E085',
      [Blockchains.Polygon]: '0xc9C2c2Ddc24d11324A9aA27d97F474EE2Dad017F',
      [Blockchains.Ethereum]: '0xbccfe69cbdbb78b82e989f8c7fd641cd4c56efdc'
    }
  }
}

export default Config

export * from './contracts'
