import {
  DangerButton,
  Input,
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  PrimaryButton
} from '@unifiprotocol/uikit'
import { PropsWithChildren, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import Config from '../../../Config'
import { MultiSigTransaction } from '../../../Contracts/MultiSig/Types'
import { useTransactionsMetadata } from '../../../Hooks/useTransactionsMetadata'
import { Auth } from '../../../State/Auth'

type HideTransactionModalProps = PropsWithChildren<{
  transaction: MultiSigTransaction
  close: Function
}>

const StyledModal = styled(Modal)`
  margin-top: 20vh !important;
`

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > * {
    margin-top: 1rem;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

export const CommentTransactionModal = ({ transaction, close }: HideTransactionModalProps) => {
  const { adapter, department } = useRecoilValue(Auth)
  const { addCommentTransaction } = useTransactionsMetadata()
  const [commentary, setCommentary] = useState(transaction.commentary || '')

  const blockchain = adapter!.blockchainConfig.blockchain
  const multisignAddress = Config.contracts[department!]![blockchain]!

  return (
    <StyledModal>
      <ModalHeader>
        <span>Set comment</span>
        <ModalClose onClick={() => close()}></ModalClose>
      </ModalHeader>
      <StyledModalBody>
        Commentary:
        <Input
          value={commentary}
          onChange={(ev) => setCommentary(ev.target.value)}
          type="text"
          aria-label="Commentary"
        />
        <ButtonsWrapper>
          <DangerButton onClick={() => close()}>Cancel</DangerButton>
          <PrimaryButton
            onClick={() => {
              addCommentTransaction(blockchain, multisignAddress, transaction.id, commentary).then(
                () => close()
              )
            }}
          >
            Update commentary
          </PrimaryButton>
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  )
}
