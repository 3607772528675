import { BN } from '@unifiprotocol/utils'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Clock from '../../Utils/Clock'
import { EventPipe } from '../../Utils/EventPipe'

const RefreshBarWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding: 0.25rem 1rem;
  font-size: 0.9rem;
`

export const RefreshBar = () => {
  const [lastUpdate, setLastUpdate] = useState(Date.now())
  const [remainingTime, setRemainingTime] = useState(10)

  const forceRefresh = useCallback(() => {
    EventPipe.emit('REFRESH')
  }, [])

  useEffect(() => {
    const fn = () => {
      setRemainingTime(
        BN(10).minus(BN(Date.now()).minus(lastUpdate).dividedBy(1000)).dp(0).toNumber()
      )
    }
    const pid = setInterval(fn, 1000)
    return () => {
      clearInterval(pid)
    }
  }, [lastUpdate])

  useEffect(() => {
    const fn = () => setLastUpdate(Date.now())
    Clock.on('TEN_SECONDS', fn)
    return () => {
      Clock.off('TEN_SECONDS', fn)
    }
  }, [])

  return (
    <RefreshBarWrapper>
      Next refresh in {remainingTime} seconds{' '}
      <span style={{ cursor: 'pointer' }} onClick={forceRefresh}>
        🔄
      </span>
    </RefreshBarWrapper>
  )
}
