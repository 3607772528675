import React from 'react'
import { ShinyHeader } from '@unifiprotocol/uikit'
import { AdminWrapper } from './Styles'
import { AddOwner } from './Components/AddOwner'
import { ConfirmationsNumber } from './Components/ConfirmationsNumber'
import { RemoveOwner } from './Components/RemoveOwner'
import { HelpWrapper } from '../SubmitTransaction/Styles'
import { useRecoilValue } from 'recoil'
import { MultiSig } from '../../State/MultiSig'

export const Admin = () => {
  const { owners } = useRecoilValue(MultiSig)

  return (
    <AdminWrapper>
      <ShinyHeader>Admin</ShinyHeader>
      <HelpWrapper>
        <h2>Current owners:</h2>
        <ul>
          {owners.map((owner, idx) => (
            <li key={idx}>{owner}</li>
          ))}
        </ul>
      </HelpWrapper>
      <h2>Add Owner</h2>
      <AddOwner />
      <h2>Remove Owner</h2>
      <RemoveOwner />
      <h2>Confirmations Number</h2>
      <ConfirmationsNumber />
    </AdminWrapper>
  )
}
