import React, { useCallback, useState } from 'react'
import { Input, PrimaryButton, SecondaryButton } from '@unifiprotocol/uikit'
import { ActionsWrapper } from '../../SubmitTransaction/Styles'
import { useMultiSig } from '../../../Hooks/useMultiSig'
import { isValidEthAddress } from '../../../Utils/Crypto'

export const AddOwner = () => {
  const [addOwnerAddress, setAddOwnerAddress] = useState('')
  const { addOwner } = useMultiSig()

  const onAddOwnerClick = useCallback(() => {
    if (!isValidEthAddress(addOwnerAddress)) {
      throw Error('Not valid ETH address')
    }
    addOwner(addOwnerAddress).then(console.log)
  }, [addOwner, addOwnerAddress])

  return (
    <>
      <Input onChange={(evt) => setAddOwnerAddress(evt.currentTarget.value)} />
      <ActionsWrapper>
        <SecondaryButton onClick={() => setAddOwnerAddress('')}>Clear</SecondaryButton>
        <PrimaryButton onClick={onAddOwnerClick}>Add Owner</PrimaryButton>
      </ActionsWrapper>
    </>
  )
}
