import { ContractUseCase, IAdapter } from '@unifiprotocol/core-sdk'
import { MultiSigContractMethods, TMultiSigParams } from './Types'

export class GetOwners extends ContractUseCase<MultiSigContractMethods, [], string[]> {
  constructor({ contractAddress }: TMultiSigParams) {
    super(contractAddress, MultiSigContractMethods.getOwners, [], false)
  }

  execute(adapter: IAdapter) {
    return super.execute(adapter).then((res) => {
      const rawValue = res.value as unknown as string[]
      return {
        ...res,
        value: rawValue
      }
    })
  }
}
