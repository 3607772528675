import { Blockchains } from '@unifiprotocol/core-sdk'

export enum MultiSigContractMethods {
  'getOwners' = 'getOwners',
  transactions = 'transactions',
  getTransactions = 'getTransactions',
  confirmationsNumber = 'confirmationsNumber',
  setConfirmationsNumber = 'setConfirmationsNumber',
  addOwner = 'addOwner',
  removeOwner = 'removeOwner',
  executeTransaction = 'executeTransaction',
  submitTransaction = 'submitTransaction',
  confirmTransaction = 'confirmTransaction',
  revokeConfirmation = 'revokeConfirmation'
}

export type TMultiSigParams<T = any> = {
  contractAddress: string
  params: T
}

export type MultiSigTransaction = {
  id: number
  to: string
  value: string
  data: string
  executed: boolean
  numConfirmations: number
  hidden?: boolean
  commentary?: string
  blockchain: Blockchains
}
