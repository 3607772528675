import { Blockchains, getBlockchainConfig } from '@unifiprotocol/core-sdk'
import { ethers } from 'ethers'
import { Interface, TransactionDescription } from 'ethers/lib/utils'
import { useCallback } from 'react'
import { blockchainInfo } from '../Config'
import { MultiSigTransaction } from '../Contracts/MultiSig/Types'

const parseResponseToInterface = async (response: Response): Promise<Interface> => {
  const result = (await response.json()).result
  return new Interface(JSON.parse(result))
}

export const useContracts = () => {
  const getExplorerAddressEndpoint = useCallback((blockchain: Blockchains, address: string) => {
    const cfg = getBlockchainConfig(blockchain)
    return cfg.explorer.address(address)
  }, [])

  const getExplorerAbiEndpoint = useCallback(
    (blockchain: Blockchains, address: string) =>
      blockchain &&
      blockchainInfo[blockchain] &&
      blockchainInfo[blockchain]?.blockexplorerEndpoints &&
      blockchainInfo[blockchain]?.blockexplorerEndpoints.abi(address),
    []
  )

  const getContracts = useCallback(
    (blockchain: Blockchains) => (blockchain && blockchainInfo[blockchain]?.contracts) || [],
    []
  )

  const inferInterfaceByAddress = useCallback(
    async (blockchain: Blockchains, address: string): Promise<Interface | null> => {
      try {
        const abiEndpoint = getExplorerAbiEndpoint(blockchain, address)

        if (!abiEndpoint) return null
        return await fetch(abiEndpoint).then(parseResponseToInterface)
      } catch (e) {
        return null
      }
    },
    [getExplorerAbiEndpoint]
  )

  const decodeTransaction = useCallback(
    async (transaction: MultiSigTransaction): Promise<TransactionDescription | null> => {
      try {
        const { blockchain } = transaction
        const contracts = blockchainInfo[blockchain]?.contracts
        if (contracts === undefined) return null
        const matches = contracts.filter(
          (contract) =>
            contract.address &&
            ethers.utils.getAddress(contract.address) === ethers.utils.getAddress(transaction?.to)
        )
        for (const match of matches) {
          try {
            const scInterface = new Interface(match.abi || [])
            return scInterface.parseTransaction(transaction)
          } catch (e) {}
        }
        const abiEndpoint = getExplorerAbiEndpoint(blockchain, transaction.to)
        if (!abiEndpoint) return null
        const scInterface = await fetch(abiEndpoint).then(parseResponseToInterface)
        return scInterface?.parseTransaction(transaction)
      } catch (e) {
        return null
      }
    },
    [getExplorerAbiEndpoint]
  )

  return {
    inferInterfaceByAddress,
    decodeTransaction,
    getExplorerAddressEndpoint,
    getContracts
  }
}
