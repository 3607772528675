import {
  Input,
  PrimaryButton,
  ShinyHeader,
  TokenInput,
  useNotifications
} from '@unifiprotocol/uikit'
import { BN } from '@unifiprotocol/utils'
import { ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { FC, useCallback, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useMultiSig } from '../../Hooks/useMultiSig'
import { Auth } from '../../State/Auth'
import { FundsTransferralWrapper, ButtonSubmitTransferralWrapper } from './Styles'

export const FundsTransferral: FC = () => {
  const [amount, setAmount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const { submitTransfer, balance } = useMultiSig()
  const { adapter } = useRecoilValue(Auth)
  const { notify } = useNotifications()

  const submitTransferral = useCallback(() => {
    try {
      const parsedAmountString = ethers.utils.parseUnits(amount).toHexString()
      submitTransfer(address, parsedAmountString).then((response) => {
        if (response.success) {
          notify({
            position: 'top-right',
            appearance: 'success',
            content: 'Transferral submitted successfully.'
          })
        } else {
          notify({
            position: 'top-right',
            appearance: 'error',
            content: "Transferral couldn't be submitted."
          })
        }
      })
    } catch (e) {
      throw new Error('Error submitting transferral, check inputs')
    }
  }, [amount, address, submitTransfer, notify])

  const disableSubmitButton = useMemo(() => {
    return (
      !ethers.utils.isAddress(address) ||
      !amount ||
      !balance ||
      ethers.utils.parseEther(amount).gt(balance._hex)
    )
  }, [address, amount, balance])

  const onAmountChange = useCallback((amount) => {
    const parsedAmount = BN(amount)
    const TOKEN_MAX_DECIMALS = 18
    parsedAmount.decimalPlaces() > TOKEN_MAX_DECIMALS
      ? setAmount(parsedAmount.toFixed(TOKEN_MAX_DECIMALS).toString())
      : setAmount(amount)
  }, [])

  const parsedBalance = useMemo(() => balance && formatEther(balance)?.toString(), [balance])
  const balanceLoading = useMemo(() => balance === undefined, [balance])
  const onAddressChange = useCallback((ev) => setAddress(ev.target.value), [setAddress])

  return (
    <FundsTransferralWrapper>
      <ShinyHeader>Transfer Contract Funds</ShinyHeader>
      <h2>Funds to be moved</h2>
      <TokenInput
        balanceLabel="Balance"
        balanceLoading={balanceLoading}
        balance={parsedBalance}
        label={`Amount (${adapter?.blockchainConfig.nativeToken.symbol})`}
        amount={amount}
        disableTokenChange={true}
        onAmountChange={onAmountChange}
      ></TokenInput>
      <h2>Destination address</h2>
      <Input
        placeholder="Destination address"
        value={address}
        type="text"
        onChange={onAddressChange}
      />
      <ButtonSubmitTransferralWrapper>
        <PrimaryButton disabled={disableSubmitButton} onClick={submitTransferral}>
          Submit transaction
        </PrimaryButton>
      </ButtonSubmitTransferralWrapper>
    </FundsTransferralWrapper>
  )
}
