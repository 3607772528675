import { Checkbox, ShinyHeader } from '@unifiprotocol/uikit'
import { useMemo, useState } from 'react'
import { getBlockchainConfig } from '@unifiprotocol/core-sdk'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { useMultiSig } from '../../Hooks/useMultiSig'
import { Auth } from '../../State/Auth'
import { MultiSig } from '../../State/MultiSig'
import { HelpWrapper } from '../SubmitTransaction/Styles'
import { Transaction } from './Components/Transaction'

const HomeWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    padding: 0 1rem;
  }
`

const TransactionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0.5rem;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Home = () => {
  const { adapter } = useRecoilValue(Auth)
  const { numConfirmations, transactions } = useRecoilValue(MultiSig)
  const [showHiddenTransactions, setShowHiddenTransactions] = useState(false)
  const { multiSigAddress } = useMultiSig()
  const blockchain = adapter?.blockchainConfig.blockchain

  const pendingTransactions = useMemo(
    () => transactions.filter((t) => t.executed === false && (showHiddenTransactions || !t.hidden)),
    [transactions, showHiddenTransactions]
  )

  const multisigLink = useMemo(() => {
    if (!blockchain) return undefined
    const cfg = getBlockchainConfig(blockchain)
    return cfg.explorer.address(multiSigAddress)
  }, [blockchain, multiSigAddress])

  return (
    <HomeWrapper>
      <HeaderWrapper>
        <ShinyHeader>Home</ShinyHeader>
        <Checkbox
          label="Show hidden transactions"
          checked={showHiddenTransactions}
          onChange={(ev) => setShowHiddenTransactions(ev)}
        />
      </HeaderWrapper>
      <HelpWrapper>
        <p>
          Multisig contract address:{' '}
          <a href={multisigLink ? multisigLink : '#'} target="_blank" rel="noreferrer">
            <i>{multiSigAddress}</i>
          </a>
        </p>
        <p>
          Showing <b>{pendingTransactions.length}</b> pending transactions, can be unexecuted or
          pending for confirmations.
          <br />
          Currently the minimum confirmations number is set to <b>{numConfirmations}</b>.
        </p>
      </HelpWrapper>
      <TransactionsWrapper>
        {pendingTransactions.map((t, i) => (
          <Transaction transaction={t} key={i} />
        ))}
      </TransactionsWrapper>
    </HomeWrapper>
  )
}
