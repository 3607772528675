import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

const StyledText = styled.p`
  color: ${(p) => p.theme.primary};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`

export const ActionText = ({
  onClick,
  children
}: {
  onClick: MouseEventHandler
  children: React.ReactNode
}) => <StyledText onClick={onClick}>{children}</StyledText>
