import { UnifiThemeProvider, Themes, useNotifications } from '@unifiprotocol/uikit'
import { RecoilRoot } from 'recoil'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Auth } from './Components/Auth'
import { Home } from './Views/Home'
import { SubmitTransaction } from './Views/SubmitTransaction'
import { TopHeader } from './Components/TopHeader'
import { BodyWrapper } from './Components/BodyWrapper'
import { Admin } from './Views/Admin'
import { Updater } from './Components/Updater'
import { Transactions } from './Views/Transactions'
import { ErrorBoundary } from './Components/ErrorBoundary'
import { FundsTransferral } from './Views/FundsTransferral'
import { ReadContract } from './Views/ReadContract'
import { Summary } from './Views/Summary'

import './App.css'

function App() {
  const { notify } = useNotifications()
  return (
    <ErrorBoundary notify={notify}>
      <Toaster position="top-right" />
      <RecoilRoot>
        <Router>
          <UnifiThemeProvider theme={{ ...Themes.Dark }}>
            <Auth>
              <Updater />
              <TopHeader />
              <BodyWrapper>
                <Routes>
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/submit-transaction" element={<SubmitTransaction />} />
                  <Route path="/read-contract" element={<ReadContract />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/funds-transferral" element={<FundsTransferral />} />
                  <Route path="/summary" element={<Summary />} />
                  <Route path="/" element={<Home />} />
                </Routes>
              </BodyWrapper>
            </Auth>
          </UnifiThemeProvider>
        </Router>
      </RecoilRoot>
    </ErrorBoundary>
  )
}

export default App
