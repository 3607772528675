import styled from 'styled-components'

export const FundsTransferralWrapper = styled.div`
  display: block;
  > * {
    margin-top: 1rem;
  }
  h2 {
    margin: 0;
  }
`

export const ButtonSubmitTransferralWrapper = styled.div`
  width: 100%;
  background: ${(p) => p.theme.bgAlt};
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
