import styled from 'styled-components'

export const AdminWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    padding: 0 1rem;
  }
`

export const ActionsWrapper = styled.div`
  margin-top: 1rem;
  background: ${(props) => props.theme.bgAlt};
  padding: 1rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  justify-content: flex-end;
`
