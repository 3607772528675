import { Blockchains, getBlockchainOfflineConnector } from '@unifiprotocol/core-sdk'
import { useCallback } from 'react'
import Config from '../Config'
import { GetTransactions } from '../Contracts/MultiSig/getTransactions'
import { MultiSigTransaction } from '../Contracts/MultiSig/Types'
import MultiSigArtifact from '../Contracts/ABI/MultiSigWallet.json'
import { useTransactionsMetadata } from './useTransactionsMetadata'

export type PendingTrxsMap = { [d in Department]?: DepartmentPendingTrxMap }
export type DepartmentPendingTrxMap = { [b in Blockchains]?: MultiSigTransaction[] }
type Department = keyof typeof Config.contracts

export const useOfflineContracts = () => {
  const { getHideableTransactions } = useTransactionsMetadata()

  const getPendingTrxsByDepartment = useCallback(
    async (department: Department): Promise<DepartmentPendingTrxMap> => {
      const multisigContracts = Config.contracts[department]
      if (multisigContracts === undefined) return {}

      const promises = Object.entries(multisigContracts).map(async ([_blockchain, address]) => {
        const blockchain = _blockchain as Blockchains
        const connector = getBlockchainOfflineConnector(blockchain)
        const { adapter } = await connector.connect()

        adapter.initializeContract(address, MultiSigArtifact.abi)
        const trxs = await new GetTransactions({
          contractAddress: address,
          params: {}
        })
          .execute(adapter)
          .then(({ value }) => value)

        const pendingTrxs = trxs.filter(({ executed }) => !executed)

        const expandedTrxList = await getHideableTransactions(blockchain, address, pendingTrxs)

        return [blockchain, expandedTrxList]
      })

      return Object.fromEntries(await Promise.all(promises))
    },
    [getHideableTransactions]
  )

  const getPendingTrxs = useCallback(async (): Promise<PendingTrxsMap> => {
    const promises = Object.keys(Config.contracts).map(
      async (department: keyof typeof Config.contracts) => {
        return [department, await getPendingTrxsByDepartment(department)]
      }
    )

    return Object.fromEntries(await Promise.all(promises))
  }, [getPendingTrxsByDepartment])

  return { getPendingTrxs }
}
