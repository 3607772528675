import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > ul {
    display: inline;

    li {
      margin-right: 1.5rem;
      cursor: pointer;
      display: inline;
      transition: color 0.3s;

      &:hover {
        color: ${(props) => props.theme.primary};
      }
    }
  }

  ${(props) => props.theme.mediaQueries.xs} {
    background: ${(props) => props.theme.bgAlt};
    overflow-x: scroll;
    padding: 0.5rem 1rem;

    ul {
      display: flex;
      margin-block-start: 0;
      padding-inline-start: 0;
      margin-block-end: 0;
    }
  }
`

export const NavigationMenu = () => {
  const navigate = useNavigate()

  return (
    <NavigationWrapper>
      <ul>
        <li onClick={() => navigate('/')}>Home</li>
        <li onClick={() => navigate('/transactions')}>Transactions</li>
        <li onClick={() => navigate('/submit-transaction')}>Submit Transaction</li>
        <li onClick={() => navigate('/read-contract')}>Read Contract</li>
        <li onClick={() => navigate('/funds-transferral')}>Funds Transferral</li>
        <li onClick={() => navigate('/admin')}>Admin</li>
        <li onClick={() => navigate('/summary')}>Overview</li>
      </ul>
    </NavigationWrapper>
  )
}
