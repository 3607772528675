import styled from 'styled-components'

export const BlockchainOption = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  background: ${(props) => props.theme.bg};
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 800;
  transition: 0.3s background;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  > img {
    width: 20px;
    height: auto;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: ${(props) => props.theme.bgAlt2};
  }
`
