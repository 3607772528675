import { FC } from 'react'

export const ExternalLink: FC<{ href?: string }> = (props) => {
  const { children, ...otherProps } = props
  return (
    <a {...otherProps} onClick={(e) => e.stopPropagation()} target="_blank">
      {children}
    </a>
  )
}
