import React, { useCallback, useMemo, useState } from 'react'
import { MultiSigTransaction } from '../../../Contracts/MultiSig/Types'
import styled from 'styled-components'
import {
  BiCommentAdd,
  BiHide,
  BiShowAlt,
  NotificationAppearance,
  PrimaryButton,
  ShinyWrapper,
  useModal,
  useNotifications
} from '@unifiprotocol/uikit'
import { useMultiSig } from '../../../Hooks/useMultiSig'
import { useRecoilValue } from 'recoil'
import { MultiSig } from '../../../State/MultiSig'
import { BN } from '@unifiprotocol/utils'
import { TransactionInfoModal } from './TransactionInfoModal'
import { ExternalLink } from '../../../Components/ExternalLink'
import { useContracts } from '../../../Hooks/useContracts'
import { ethers } from 'ethers'
import { useDecodedTransaction } from '../../../Hooks/useDecodedTransaction'
import { HideTransactionModal } from './HideTransactionModal'
import { CommentTransactionModal } from './CommentTransactionModal'

const TransactionWrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.bgAlt};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 25rem;
  height: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  ${(props) => props.theme.mediaQueries.xs} {
    width: 100%;
  }
`

const TransactionInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const TransactionInfoLabel = styled.div`
  font-weight: 900;
  font-size: 0.9rem;
`

const TransactionInfoDetail = styled.div`
  font-size: 0.8rem;
  word-break: break-all;
`

const TransactionActions = styled.div`
  display: flex;
  margin-top: 0.5rem;

  > button {
    margin: 0 0.5rem;
    width: 100%;
  }
`

const IconsWrapper = styled.span`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.5rem;
`

const IconWrapper = styled.span`
  padding: 0.2rem;
  font-size: 1rem;
  &:hover {
    opacity: 0.7;
  }
`

const TransactionComment = styled(TransactionInfo)`
  padding: 0.5rem;
  margin-top: 0.5rem;
  background: ${(p) => p.theme.bgAlt2};
  border-radius: 0.5rem;
`

export const Transaction: React.FC<{ transaction: MultiSigTransaction }> = ({ transaction }) => {
  const { numConfirmations } = useRecoilValue(MultiSig)
  const { getExplorerAddressEndpoint } = useContracts()
  const { confirmTransaction, revokeTransaction, executeTransaction } = useMultiSig()
  const [isInvalid, setIsInvalid] = useState<boolean>()
  const { notify } = useNotifications()
  const toAddressBlockExplorerEndpoint = useMemo(
    () => getExplorerAddressEndpoint(transaction.blockchain, transaction.to),
    [getExplorerAddressEndpoint, transaction.to, transaction.blockchain]
  )

  const { methodName, decodedTransaction, decodingTransaction } = useDecodedTransaction(transaction)

  const [openDetails] = useModal({
    component: TransactionInfoModal,
    props: { transaction },
    options: {
      disableBackdropClick: true
    }
  })

  const onClick = useCallback(() => openDetails(), [openDetails])

  const parsedValue = useMemo(() => {
    try {
      if (transaction.value) debugger
      return ethers.utils.formatEther(transaction.value.toString())
    } catch (e) {
      setIsInvalid(true)
      return 'Invalid value'
    }
  }, [transaction.value])

  const [openHide, closeHide] = useModal({
    component: HideTransactionModal,
    props: { transaction },
    options: {
      onClose: () => closeHide()
    }
  })

  const onHideClick = useCallback(
    (e) => {
      e.stopPropagation()
      openHide()
    },
    [openHide]
  )

  const [openCommentModal, closeComment] = useModal({
    component: CommentTransactionModal,
    props: { transaction },
    options: {
      onClose: () => closeComment()
    }
  })

  const onCommentClick = useCallback(
    (e) => {
      e.stopPropagation()
      openCommentModal()
    },
    [openCommentModal]
  )

  const onConfirmationClick = useCallback(
    (e) => {
      e.stopPropagation()
      confirmTransaction(transaction.id)
    },
    [confirmTransaction, transaction.id]
  )

  const onRevokeClick = useCallback(
    (e) => {
      e.stopPropagation()
      revokeTransaction(transaction.id)
    },
    [revokeTransaction, transaction.id]
  )

  const onExecuteClick = useCallback(
    async (e) => {
      e.stopPropagation()
      const response = await executeTransaction(transaction.id)
      const notification = response.success
        ? {
            appearance: 'success' as NotificationAppearance,
            content: 'Transaction executed successfully'
          }
        : {
            appearance: 'error' as NotificationAppearance,
            content: 'Transaction failed to be executed.'
          }
      response.err && console.log(response.err)
      notify(notification)
    },
    [executeTransaction, transaction.id, notify]
  )

  return (
    <ShinyWrapper mode="manual" active={!transaction.executed}>
      <TransactionWrapper onClick={onClick}>
        <IconsWrapper>
          <IconWrapper>
            <BiCommentAdd onClick={onCommentClick} />
          </IconWrapper>
          <IconWrapper onClick={onHideClick}>
            {transaction.hidden ? <BiShowAlt /> : <BiHide />}
          </IconWrapper>
        </IconsWrapper>
        <div>
          <TransactionInfo>
            <TransactionInfoLabel>ID</TransactionInfoLabel>
            <TransactionInfoDetail>#{transaction.id}</TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>To (Interacting with)</TransactionInfoLabel>
            <TransactionInfoDetail>
              <ExternalLink href={toAddressBlockExplorerEndpoint}>{transaction.to}</ExternalLink>
            </TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>Value</TransactionInfoLabel>
            <TransactionInfoDetail>{parsedValue} ETH</TransactionInfoDetail>
          </TransactionInfo>
          {!decodingTransaction && decodedTransaction ? (
            <TransactionInfo>
              <TransactionInfoLabel>Method Name</TransactionInfoLabel>
              <TransactionInfoDetail>{methodName}</TransactionInfoDetail>
            </TransactionInfo>
          ) : (
            <TransactionInfo>
              <TransactionInfoLabel>Data</TransactionInfoLabel>
              <TransactionInfoDetail>{transaction.data}</TransactionInfoDetail>
            </TransactionInfo>
          )}
          <TransactionInfo>
            <TransactionInfoLabel>Executed</TransactionInfoLabel>
            <TransactionInfoDetail>{String(transaction.executed)}</TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>Confirmations</TransactionInfoLabel>
            <TransactionInfoDetail>{transaction.numConfirmations}</TransactionInfoDetail>
          </TransactionInfo>
          {transaction.commentary && (
            <TransactionComment>
              <TransactionInfoLabel>Comment</TransactionInfoLabel>
              <TransactionInfoDetail>{transaction.commentary}</TransactionInfoDetail>
            </TransactionComment>
          )}
        </div>
        {!transaction.executed && (
          <TransactionActions>
            <PrimaryButton onClick={onConfirmationClick} disabled={isInvalid}>
              Confirm
            </PrimaryButton>
            <PrimaryButton onClick={onRevokeClick} disabled={isInvalid}>
              Revoke
            </PrimaryButton>
            <PrimaryButton
              disabled={BN(transaction.numConfirmations).lt(numConfirmations) || isInvalid}
              onClick={onExecuteClick}
            >
              Execute
            </PrimaryButton>
          </TransactionActions>
        )}
      </TransactionWrapper>
    </ShinyWrapper>
  )
}
