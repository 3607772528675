import { ContractUseCase, IAdapter } from '@unifiprotocol/core-sdk'
import { toHex } from '@unifiprotocol/utils'
import { ethers } from 'ethers'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

type SubmitTransferParams = {
  to: string
  value: string
  abi: ethers.utils.Interface
}

export class SubmitTransfer extends ContractUseCase<
  MultiSigContractMethods,
  SubmitTransferParams,
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<SubmitTransferParams>) {
    super(contractAddress, MultiSigContractMethods.submitTransaction, params!, true)
  }

  getArgs() {
    return [this.params.to, toHex(this.params.value), '0x']
  }

  execute(adapter: IAdapter) {
    adapter.initializeContract(this.params.to, this.params.abi)
    return super.execute(adapter)
  }
}
