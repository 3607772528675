import styled from 'styled-components'

export const SubmitTransactionWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    padding: 0 1rem;
  }
`

export const SubmitTransactionBody = styled.div`
  display: flex;

  ${(props) => props.theme.mediaQueries.xs} {
    flex-direction: column;
  }

  > div {
    width: 100%;
    margin: 0 0.5rem;
  }
`

export const HelpWrapper = styled.div`
  background: ${(props) => props.theme.bgAlt};
  padding: 1rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius};
  word-wrap: break-word;

  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }
`

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const OpacityPlaceholder = styled.p`
  opacity: ${({ opacity }: { opacity: number }) => opacity};
  font-size: 0.75rem;
`

export const ActionsWrapper = styled.div`
  margin-top: 1rem;
  background: ${(props) => props.theme.bgAlt};
  padding: 1rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius};
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: 1rem;
  }
`

export const ParameterWrapper = styled.div`
  :not(:first-of-type) {
    margin-top: 1rem;
  }
`

export const ParameterTitle = styled.div`
  font-style: italic;
  font-size: 0.9rem;
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const ButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 5%;
  top: 0;
  bottom: 0;
  margin: auto;
`
