import { getBlockchainConfig, getBlockchainExplorer } from '@unifiprotocol/core-sdk'
import { ShinyWrapper, useModal } from '@unifiprotocol/uikit'
import { utils } from 'ethers'
import { useMemo } from 'react'
import styled from 'styled-components'
import { MultiSigTransaction } from '../../../Contracts/MultiSig/Types'
import { useDecodedTransaction } from '../../../Hooks/useDecodedTransaction'
import { TransactionInfoModal } from '../../Home/Components/TransactionInfoModal'
import { ExternalLink } from '../../../Components/ExternalLink'

const TransactionWrapper = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.bgAlt};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 25rem;
  height: 100%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  ${(props) => props.theme.mediaQueries.xs} {
    width: 100%;
  }
`

const TransactionInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const TransactionInfoLabel = styled.div`
  font-weight: 900;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const TransactionInfoDetail = styled.div`
  font-size: 0.8rem;
  word-break: break-all;
`

const BlockchainLogo = styled.img`
  width: 1.5rem;
  border-radius: 100%;
`

const TransactionComment = styled(TransactionInfo)`
  padding: 0.5rem;
  margin-top: 0.5rem;
  background: ${(p) => p.theme.bgAlt2};
  border-radius: 0.5rem;
`

export const TransactionSummary = ({ transaction }: { transaction: MultiSigTransaction }) => {
  const toAddressBlockExplorerEndpoint = useMemo(() => {
    return getBlockchainExplorer(transaction.blockchain).address(transaction.to)
  }, [transaction.to, transaction.blockchain])

  const parsedValue = useMemo(() => utils.formatEther(transaction.value), [transaction.value])

  const { decodingTransaction, methodName, decodedTransaction } = useDecodedTransaction(transaction)

  const [openDetails] = useModal({
    component: TransactionInfoModal,
    props: { transaction },
    options: {
      disableBackdropClick: true
    }
  })

  return (
    <ShinyWrapper mode="manual" active={!transaction.executed}>
      <TransactionWrapper onClick={() => openDetails()}>
        <div>
          <TransactionInfo>
            <TransactionInfoLabel>
              {transaction.blockchain}{' '}
              <BlockchainLogo src={getBlockchainConfig(transaction.blockchain).logoURI} />
            </TransactionInfoLabel>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>ID</TransactionInfoLabel>
            <TransactionInfoDetail>#{transaction.id}</TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>To (Interacting with)</TransactionInfoLabel>
            <TransactionInfoDetail>
              <ExternalLink href={toAddressBlockExplorerEndpoint}>{transaction.to}</ExternalLink>
            </TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>Value</TransactionInfoLabel>
            <TransactionInfoDetail>{parsedValue} ETH</TransactionInfoDetail>
          </TransactionInfo>
          {!decodingTransaction && decodedTransaction ? (
            <TransactionInfo>
              <TransactionInfoLabel>Method</TransactionInfoLabel>
              <TransactionInfoDetail>{methodName}</TransactionInfoDetail>
            </TransactionInfo>
          ) : (
            <TransactionInfo>
              <TransactionInfoLabel>Data</TransactionInfoLabel>
              <TransactionInfoDetail>{transaction.data}</TransactionInfoDetail>
            </TransactionInfo>
          )}
          <TransactionInfo>
            <TransactionInfoLabel>Executed</TransactionInfoLabel>
            <TransactionInfoDetail>{String(transaction.executed)}</TransactionInfoDetail>
          </TransactionInfo>
          <TransactionInfo>
            <TransactionInfoLabel>Confirmations</TransactionInfoLabel>
            <TransactionInfoDetail>{transaction.numConfirmations}</TransactionInfoDetail>
          </TransactionInfo>
          {transaction.commentary && (
            <TransactionComment>
              <TransactionInfoLabel>Comment</TransactionInfoLabel>
              <TransactionInfoDetail>{transaction.commentary}</TransactionInfoDetail>
            </TransactionComment>
          )}
        </div>
      </TransactionWrapper>
    </ShinyWrapper>
  )
}
