import { ParamType } from 'ethers/lib/utils'
import { useCallback, useState } from 'react'

type Parameters = { [k: string]: string }

export const useTransactionParams = () => {
  const [params, setParams] = useState<Parameters>({})

  const resetParams = useCallback(() => {
    setParams({})
  }, [setParams])

  const setParam = useCallback(
    (type, key, value) => {
      return setParams((params) => ({
        ...params,
        [key]: value
      }))
    },
    [setParams]
  )

  const getParam = useCallback(
    (key) => {
      return params[key]
    },
    [params]
  )

  const parseParamValue = useCallback((param: ParamType, value: string) => {
    let parsedParam
    try {
      const splitted = value.split(',')
      if (['array', 'tuple'].includes(param.baseType) && splitted.length === 1) {
        parsedParam = splitted.filter((x) => !!x)
      } else {
        parsedParam = value
      }
    } catch (e) {
      parsedParam = value.split(',')[0]
    }
    return parsedParam
  }, [])

  return { params, setParams, resetParams, setParam, getParam, parseParamValue }
}
