import { SignatureLike } from '@ethersproject/bytes'
import { ethers } from 'ethers'

export function isValidSignature(
  message: string,
  signature: SignatureLike,
  expectedAddress: string
) {
  try {
    const signer = ethers.utils.verifyMessage(message, signature)
    return signer.toLowerCase() === expectedAddress.toLowerCase()
  } catch (err) {
    return false
  }
}

export function isValidEthAddress(address: string) {
  return ethers.utils.isAddress(address)
}
