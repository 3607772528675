import { GenericUseCase } from '@unifiprotocol/core-sdk'
import { BigNumber, ethers } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'
import Config from '../Config'
import { AddOwner } from '../Contracts/MultiSig/addOwner'
import { GetOwners } from '../Contracts/MultiSig/getOwners'
import { GetTransactions } from '../Contracts/MultiSig/getTransactions'
import { SubmitTransaction } from '../Contracts/MultiSig/submitTransaction'
import { Auth } from '../State/Auth'
import MultiSigAbi from '../Contracts/ABI/MultiSigWallet.json'
import { RemoveOwner } from '../Contracts/MultiSig/removeOwner'
import { SetConfirmationsNumber } from '../Contracts/MultiSig/setConfirmationsNumber'
import { GetConfirmationsNumber } from '../Contracts/MultiSig/getConfirmationsNumber'
import { ConfirmTransaction } from '../Contracts/MultiSig/confirmTransaction'
import { RevokeTransaction } from '../Contracts/MultiSig/revokeTransaction'
import { ExecuteTransaction } from '../Contracts/MultiSig/executeTransaction'
import { SubmitTransfer } from '../Contracts/MultiSig/submitTransfer'

export const useMultiSig = () => {
  const [{ adapter, department }] = useRecoilState(Auth)
  const blockchain = adapter?.blockchainConfig.blockchain
  const multiSigAddress = Config.contracts[department!]![blockchain!]!
  const multiSigInterface = useMemo(() => new ethers.utils.Interface(MultiSigAbi.abi), [])
  const [balance, setBalance] = useState<BigNumber>()

  useEffect(() => {
    adapter
      ?.getProvider()
      .getBalance(multiSigAddress)
      .then((e: BigNumber) => setBalance(e))
  }, [multiSigAddress, adapter])

  const getOwners = useCallback(
    () =>
      new GetOwners({
        contractAddress: multiSigAddress,
        params: []
      })
        .execute(adapter!)
        .then(({ value }) => value),
    [adapter, multiSigAddress]
  )

  const getTransactions = useCallback(
    () =>
      new GetTransactions({
        contractAddress: multiSigAddress,
        params: {}
      })
        .execute(adapter!)
        .then(({ value }) => value),
    [adapter, multiSigAddress]
  )

  const getConfirmationsNumber = useCallback(
    () =>
      new GetConfirmationsNumber({
        contractAddress: multiSigAddress,
        params: {}
      })
        .execute(adapter!)
        .then((res) => res.value),
    [adapter, multiSigAddress]
  )

  const addOwner = useCallback(
    (newOwner: string) =>
      new SubmitTransaction({
        contractAddress: multiSigAddress,
        params: {
          to: multiSigAddress,
          value: '0',
          useCase: new AddOwner({
            contractAddress: multiSigAddress,
            params: {
              newOwner
            }
          }),
          abi: multiSigInterface
        }
      }).execute(adapter!),
    [adapter, multiSigAddress, multiSigInterface]
  )

  const confirmTransaction = useCallback(
    (txIndex: number) =>
      new ConfirmTransaction({
        contractAddress: multiSigAddress,
        params: txIndex
      }).execute(adapter!),
    [adapter, multiSigAddress]
  )

  const revokeTransaction = useCallback(
    (txIndex: number) =>
      new RevokeTransaction({
        contractAddress: multiSigAddress,
        params: txIndex
      }).execute(adapter!),
    [adapter, multiSigAddress]
  )

  const executeTransaction = useCallback(
    (txIndex: number) =>
      new ExecuteTransaction({
        contractAddress: multiSigAddress,
        params: txIndex
      }).execute(adapter!),
    [adapter, multiSigAddress]
  )

  const removeOwner = useCallback(
    (owner: string) =>
      new SubmitTransaction({
        contractAddress: multiSigAddress,
        params: {
          to: multiSigAddress,
          value: '0',
          useCase: new RemoveOwner({
            contractAddress: multiSigAddress,
            params: {
              owner
            }
          }),
          abi: multiSigInterface
        }
      }).execute(adapter!),
    [adapter, multiSigAddress, multiSigInterface]
  )

  const setConfirmationsNumber = useCallback(
    (numConfirmations: number) =>
      new SubmitTransaction({
        contractAddress: multiSigAddress,
        params: {
          to: multiSigAddress,
          value: '0',
          useCase: new SetConfirmationsNumber({
            contractAddress: multiSigAddress,
            params: {
              numConfirmations
            }
          }),
          abi: multiSigInterface
        }
      }).execute(adapter!),
    [adapter, multiSigAddress, multiSigInterface]
  )

  const submitTransaction = useCallback(
    (to: string, value: string, useCase: GenericUseCase, abi: ethers.utils.Interface) =>
      new SubmitTransaction({
        contractAddress: multiSigAddress,
        params: {
          to,
          value,
          useCase,
          abi
        }
      }).execute(adapter!),
    [adapter, multiSigAddress]
  )

  const submitTransfer = useCallback(
    (to: string, value: string) =>
      new SubmitTransfer({
        contractAddress: multiSigAddress,
        params: {
          to,
          value,
          abi: multiSigInterface
        }
      }).execute(adapter!),
    [adapter, multiSigAddress, multiSigInterface]
  )

  return {
    balance,
    multiSigAddress,
    getOwners,
    getTransactions,
    addOwner,
    submitTransaction,
    removeOwner,
    setConfirmationsNumber,
    getConfirmationsNumber,
    confirmTransaction,
    executeTransaction,
    revokeTransaction,
    submitTransfer
  }
}
