import { ColumnBody, RowColumn, TableRow, useModal } from '@unifiprotocol/uikit'
import { useMemo, FC } from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { MultiSigTransaction } from '../../Contracts/MultiSig/Types'
import { TransactionInfoModal } from '../Home/Components/TransactionInfoModal'

const HoverTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
`

export const TransactionRow: FC<{ transaction: MultiSigTransaction }> = ({ transaction }) => {

  const [openDetails] = useModal({
    component: TransactionInfoModal,
    props: { transaction },
    options: {
      disableBackdropClick: true
    }
  })

  const parsedValue = useMemo(() => {
    try {
      return ethers.utils.formatEther(transaction.value.toString())
    } catch (e) {
      return 'Invalid value'
    }
  }, [transaction.value])

  const parsedExecuted = useMemo(() => JSON.stringify(transaction.executed), [transaction.executed])
  return (
    <HoverTableRow onClick={openDetails}>
      <RowColumn title={`# ID`} align="right">
        <ColumnBody align="right">
          <div>{transaction.id}</div>
        </ColumnBody>
      </RowColumn>
      <RowColumn title={`value`} align="right">
        <ColumnBody align="right">
          <div>{parsedValue} ETH</div>
        </ColumnBody>
      </RowColumn>
      <RowColumn title={`data`} align="left">
        <ColumnBody align="left">
          <div style={{ maxWidth: '30rem', wordBreak: 'break-word' }}>{transaction.data}</div>
        </ColumnBody>
      </RowColumn>
      <RowColumn title={`executed`} align="right">
        <ColumnBody align="right">
          <div>{parsedExecuted}</div>
        </ColumnBody>
      </RowColumn>
      <RowColumn title={`No Confirmations`} align="right">
        <ColumnBody align="right">
          <div>{transaction.numConfirmations}</div>
        </ColumnBody>
      </RowColumn>
    </HoverTableRow>
  )
}
