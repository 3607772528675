import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

export class SetConfirmationsNumber extends ContractUseCase<
  MultiSigContractMethods,
  number,
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<{ numConfirmations: number }>) {
    super(
      contractAddress,
      MultiSigContractMethods.setConfirmationsNumber,
      params?.numConfirmations,
      true
    )
  }

  getArgs() {
    return [this.params]
  }
}
