import React, { useCallback, useState } from 'react'
import { Input, PrimaryButton, SecondaryButton } from '@unifiprotocol/uikit'
import { ActionsWrapper } from '../../SubmitTransaction/Styles'
import { useMultiSig } from '../../../Hooks/useMultiSig'
import { isValidEthAddress } from '../../../Utils/Crypto'

export const RemoveOwner = () => {
  const [removeOwnerAddress, setRemoveOwnerAddress] = useState('')
  const { removeOwner } = useMultiSig()

  const onRemoveOwnerClick = useCallback(() => {
    if (!isValidEthAddress(removeOwnerAddress)) {
      throw Error('Not valid ETH address')
    }
    removeOwner(removeOwnerAddress).then(console.log)
  }, [removeOwner, removeOwnerAddress])

  return (
    <>
      <Input onChange={(evt) => setRemoveOwnerAddress(evt.currentTarget.value)} />
      <ActionsWrapper>
        <SecondaryButton onClick={() => setRemoveOwnerAddress('')}>Clear</SecondaryButton>
        <PrimaryButton onClick={onRemoveOwnerClick}>Remove Owner</PrimaryButton>
      </ActionsWrapper>
    </>
  )
}
