import { Modal, ModalBody, ModalHeader } from '@unifiprotocol/uikit'
import { PropsWithChildren, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { ContractInfo } from '../../../Config'
import { useContracts } from '../../../Hooks/useContracts'
import { Auth } from '../../../State/Auth'

type ContractSelectorProps = {
  onSelect: (c: ContractInfo) => any
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 20vh;
  margin: auto;
  left: 0;
  right: 0;
  height: fit-content;
`

const ContractWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ContractInformation = styled.div`
  width: 100%;
  display: flex;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  flex-direction: row;
  justify-content: space-between;
  background: #222833;
  border-radius: 0.5em;
  margin-bottom: 1em;
  min-height: 5%;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`
const ContractInformationHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5vh;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ContractType = styled.div`
  flex: 2;
  max-width: 20%;
  display: flex;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContractName = styled.div`
  flex: 2;
  display: flex;
  max-width: 20%;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const ContractAddress = styled.div`
  flex: 6;
  max-width: 60%;
  display: flex;
  align-items: center;
`

const SelectorModal = styled(Modal)`
  width: 70vw;
`

const EllipsisText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`

export const ContractSelector = ({ onSelect }: PropsWithChildren<ContractSelectorProps>) => {
  const { adapter } = useRecoilValue(Auth)
  if (!adapter) throw new Error('Adapter is not set')

  const { getContracts } = useContracts()

  const contracts = useMemo(
    () => getContracts(adapter!.blockchainConfig.blockchain),
    [getContracts, adapter]
  )
  return (
    <ModalWrapper>
      <SelectorModal>
        <ModalHeader>Select contract</ModalHeader>
        <ModalBody>
          <ContractWrapper>
            <ContractInformationHeader>
              <ContractName>Name</ContractName>
              <ContractType>Type</ContractType>
              <ContractAddress>Contract Address</ContractAddress>
            </ContractInformationHeader>
            {contracts.map((contract, i) => (
              <ContractInformation key={i} onClick={() => onSelect(contract)}>
                <ContractName>{contract.name}</ContractName>
                <ContractType>{contract.type}</ContractType>
                <ContractAddress>
                  <EllipsisText>{contract.address}</EllipsisText>
                </ContractAddress>
              </ContractInformation>
            ))}
          </ContractWrapper>
        </ModalBody>
      </SelectorModal>
    </ModalWrapper>
  )
}
