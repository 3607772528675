import { ContractUseCase } from '@unifiprotocol/core-sdk'

type TContractFactory = {
  contractAddress: string
  method: string
  parameters: any[]
  isWrite: boolean
}

export class ContractFactory {
  static get(
    props: TContractFactory
  ): ContractUseCase<TContractFactory['method'], TContractFactory['parameters']> {
    class CustomContractUseCase extends ContractUseCase<
      TContractFactory['method'],
      TContractFactory['parameters']
    > {
      constructor({ contractAddress, method, parameters, isWrite }: TContractFactory) {
        super(contractAddress, method, parameters, isWrite)
      }

      getArgs() {
        return this.params
      }
    }
    return new CustomContractUseCase({ ...props })
  }
}
