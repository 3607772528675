import React, { useMemo, useState } from 'react'
import { Checkbox, ShinyHeader, Table } from '@unifiprotocol/uikit'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { MultiSig } from '../../State/MultiSig'
import { TransactionRow } from './TransactionRow'

const TransactionsWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    padding: 0 1rem;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Transactions = () => {
  const { transactions } = useRecoilValue(MultiSig)
  const [showHiddenTransactions, setShowHiddenTransactions] = useState(false)
  const showedTransactions = useMemo(
    () => (showHiddenTransactions ? transactions : transactions.filter((trx) => !trx.hidden)),
    [transactions, showHiddenTransactions]
  )
  return (
    <TransactionsWrapper>
      <HeaderWrapper>
        <ShinyHeader>Transactions</ShinyHeader>
        <Checkbox
          label="Show hidden transactions"
          checked={showHiddenTransactions}
          onChange={setShowHiddenTransactions}
        ></Checkbox>
      </HeaderWrapper>
      <Table>
        {showedTransactions.map((t, i) => (
          <TransactionRow transaction={t} key={i} />
        ))}
      </Table>
    </TransactionsWrapper>
  )
}
