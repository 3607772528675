import { Component, ErrorInfo, ReactNode } from 'react'
import { Notification } from '@unifiprotocol/uikit'

interface Props {
  notify: (notification: Notification) => void
  children: ReactNode
}

interface State {
  hasError: boolean
  errors: string[]
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    errors: []
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
    window.addEventListener('error', this.errorHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler)
    window.removeEventListener('error', this.errorHandler)
  }

  static getDerivedStateFromError(err: Error): State {
    return { hasError: true, errors: [] }
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.props.notify({
      appearance: 'error',
      content: event.reason.toString(),
      position: 'top-right'
    })
    this.setState({
      errors: [...this.state.errors, event.reason.toString()]
    })
  }

  private errorHandler = (error: ErrorEvent) => {
    this.props.notify({
      appearance: 'error',
      content: error.error.message,
      position: 'top-right'
    })
    this.setState({
      errors: [...this.state.errors, error.message]
    })
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((st) => ({
      errors: [...st.errors, JSON.stringify(error.toString())]
    }))
    this.props.notify({
      appearance: 'error',
      content: error.toString(),
      position: 'top-right'
    })
  }

  render() {
    return this.props.children
  }
}
