import { ContractUseCase } from '@unifiprotocol/core-sdk'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

export class AddOwner extends ContractUseCase<
  MultiSigContractMethods,
  string[],
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<{ newOwner: string }>) {
    super(
      contractAddress,
      MultiSigContractMethods.addOwner,
      params?.newOwner ? [params?.newOwner] : [],
      true
    )
  }

  getArgs() {
    return this.params
  }
}
