/* eslint-disable jsx-a11y/anchor-is-valid */
import { BrandedHeader, NavigationHeader } from '@unifiprotocol/uikit'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { Auth } from '../../State/Auth'
import { shortAddress } from '@unifiprotocol/utils'
import { NavigationMenu } from './Components/NavigationMenu'

const TopHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
`

const AddressLabel = styled.span`
  background: ${(props) => props.theme.primary};
  border-radius: ${(props) => props.theme.borderRadius};
  color: #000;
  padding: 0.25rem;
  font-size: 0.9rem;
  font-weight: 900;
`

const NetworkLabel = styled(AddressLabel)`
  background: ${(props) => props.theme.bgAlt2};
  color: #fff;
`

const HideOnMobile = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    display: none;
  }
`

const HideOnDesktop = styled.div`
  ${(props) => props.theme.mediaQueries.md} {
    display: none;
  }
`

const ConnectionWrapper = styled.span`
  position: absolute;
  right: 1rem;
  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const MarginedSpan = styled.span``

export const TopHeader = () => {
  const [{ adapter }] = useRecoilState(Auth)

  return (
    <TopHeaderWrapper>
      <NavigationHeader />
      <BrandedHeader>
        <HideOnMobile>
          <NavigationMenu />
        </HideOnMobile>
        <ConnectionWrapper>
          <MarginedSpan>
            <NetworkLabel>{adapter?.blockchainConfig.blockchain}</NetworkLabel>
            <AddressLabel>{shortAddress(adapter?.getAddress() || '')}</AddressLabel>
          </MarginedSpan>
        </ConnectionWrapper>
      </BrandedHeader>
      <HideOnDesktop>
        <NavigationMenu />
      </HideOnDesktop>
    </TopHeaderWrapper>
  )
}
