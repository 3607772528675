import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Input,
  PrimaryButton,
  SelectMenu,
  ShinyHeader,
  Textarea,
  useModal,
  useNotifications
} from '@unifiprotocol/uikit'
import {
  SubmitTransactionWrapper,
  SubmitTransactionBody,
  HelpWrapper,
  ActionsWrapper,
  ParameterTitle,
  ParameterWrapper,
  InputWrapper,
  SpaceBetweenWrapper,
  OpacityPlaceholder
} from './Styles'
import { parseABI } from '../../Utils/ABI'
import { ethers } from 'ethers'
import { ContractFactory } from '../../Contracts/MultiSig/ContractFactory'
import { useMultiSig } from '../../Hooks/useMultiSig'
import { ContractSelector } from '../Home/Components/ContractSelector'
import { ContractInfo } from '../../Config'
import { Interface } from 'ethers/lib/utils'
import { Auth } from '../../State/Auth'
import { useRecoilValue } from 'recoil'
import { useTransactionParams } from '../../Hooks/useTransactionParameters'
import { useContracts } from '../../Hooks/useContracts'

export const SubmitTransaction = () => {
  const { submitTransaction } = useMultiSig()
  const { adapter } = useRecoilValue(Auth)
  const { inferInterfaceByAddress } = useContracts()
  const [textareaValue, setTextareaValue] = useState<string | null>('[]')
  const [scInterface, setScInterface] = useState<ethers.utils.Interface | undefined>()
  const [selectedMethod, setSelectedMethod] = useState<string | undefined>()
  const { params, setParam, resetParams, getParam, parseParamValue } = useTransactionParams()
  const { notify } = useNotifications()
  const [smartcontractAddress, setSmartcontractAddress] = useState('')
  const [defaultContract, setContract] = useState<ContractInfo>({ type: 'Custom', name: 'Custom' })
  const [inferred, setInferred] = useState<boolean>(false)
  const [ethValue, setEthValue] = useState('0')

  const onSelect = useCallback(
    (contract: ContractInfo) => {
      setContract(contract)
    },
    [setContract]
  )

  useEffect(() => {
    defaultContract.address && setSmartcontractAddress(defaultContract.address)
    defaultContract.abi && setScInterface(new Interface(defaultContract.abi))
    if (defaultContract.abi) {
      setTextareaValue(null)
    } else {
      setTextareaValue('[]')
      setSelectedMethod(undefined)
      setScInterface(undefined)
    }
    setInferred(false)
  }, [defaultContract])

  const [open, close] = useModal({
    component: ContractSelector,
    props: {
      onSelect: (contract) => {
        close()
        onSelect(contract)
      }
    },
    options: { disableBackdropClick: true }
  })

  useEffect(() => {
    try {
      textareaValue != null && setScInterface(parseABI(textareaValue))
      setInferred(false)
    } catch (err) {}
  }, [textareaValue])

  useEffect(() => {
    resetParams()
  }, [selectedMethod, resetParams])

  const methodFragment = useMemo(() => {
    const typedSelected = selectedMethod as keyof typeof scInterface
    if (!scInterface || !selectedMethod || !scInterface.functions[typedSelected]) return undefined
    return scInterface.functions[typedSelected] as ethers.utils.FunctionFragment
  }, [scInterface, selectedMethod])

  useEffect(() => {
    if (!defaultContract.abi) {
      inferInterfaceByAddress(adapter!.blockchainConfig.blockchain, smartcontractAddress).then(
        (contractInterface) => {
          if (contractInterface) {
            setScInterface(contractInterface)
            setInferred(true)
            setTextareaValue(null)
          } else {
            setScInterface(undefined)
            setInferred(false)
          }
        }
      )
    }
  }, [
    adapter,
    inferInterfaceByAddress,
    smartcontractAddress,
    adapter?.blockchainConfig.blockchain,
    defaultContract.abi
  ])

  const methodOptions = useMemo(() => {
    if (!scInterface) return []
    return Object.keys(scInterface.functions).reduce(
      (methods: { value: string; label: string }[], method) => {
        const contractMethod = scInterface.functions[method]
        if (!['view', 'pure'].includes(contractMethod.stateMutability)) {
          methods.push({ label: method, value: method })
        }
        return methods
      },
      []
    )
  }, [scInterface])

  useEffect(() => {
    setSelectedMethod(undefined)
  }, [methodOptions])

  const onSubmit = useCallback(() => {
    // validation
    if (!smartcontractAddress || !scInterface || !selectedMethod) {
      throw Error('Please fill all the inputs')
    }
    const method = selectedMethod.replace(/\(.*\)/, '')
    const useCase = ContractFactory.get({
      contractAddress: smartcontractAddress,
      method,
      isWrite: true,
      parameters:
        (methodFragment &&
          methodFragment.inputs.reduce((t: any[], curr) => {
            return [...t, parseParamValue(curr, params[curr.name])]
          }, [])) ??
        []
    })
    try {
      submitTransaction(smartcontractAddress.trim(), ethValue, useCase, scInterface).then((e) => {
        if (e.success) {
          notify({
            position: 'top-right',
            appearance: 'success',
            content: 'Transaction submitted successfully.'
          })
        } else {
          notify({
            position: 'top-right',
            appearance: 'error',
            content: "Transaction couldn't be submitted."
          })
          throw e
        }
      })
    } catch (e) {
      console.error('🚀 ~ file: index.tsx ~ line 168 ~ onSubmit ~ e', e)
      throw new Error('Error submiting transaction, check input fields.')
    }
  }, [
    smartcontractAddress,
    scInterface,
    selectedMethod,
    methodFragment,
    params,
    submitTransaction,
    notify,
    parseParamValue,
    ethValue
  ])

  const onAddressChange = useCallback(
    (evt) => setSmartcontractAddress(evt.currentTarget.value),
    [setSmartcontractAddress]
  )

  return (
    <SubmitTransactionWrapper>
      <ShinyHeader>Submit Transaction</ShinyHeader>
      <HelpWrapper>
        <div>
          Fill the below inputs to submit a transaction. The current form doesn't have any
          validation, so pay attention to every detail.
        </div>
      </HelpWrapper>
      <SubmitTransactionBody>
        <div>
          <SpaceBetweenWrapper>
            <h2>SmartContract Address</h2>
            <PrimaryButton onClick={open}>Select default</PrimaryButton>
          </SpaceBetweenWrapper>
          <InputWrapper>
            <Input value={smartcontractAddress} onChange={onAddressChange} />
          </InputWrapper>
          {textareaValue !== null && (
            <>
              <h2>ABI File</h2>
              <Textarea rows="10" onChange={setTextareaValue} />
            </>
          )}
        </div>
        <div>
          {scInterface && (
            <>
              <SpaceBetweenWrapper>
                <h2>Select Method</h2>
                {inferred && (
                  <OpacityPlaceholder opacity={0.7}>
                    Interface inferred from block explorer
                  </OpacityPlaceholder>
                )}
              </SpaceBetweenWrapper>
              <SelectMenu
                onChange={({ value }: { value: string }) => setSelectedMethod(value)}
                label="ABI Method"
                options={methodOptions}
              />
              <h2>Value</h2>
              <ParameterTitle>Value in Wei (default 0)</ParameterTitle>
              <Input onChange={(event) => setEthValue(event.target.value)} value={ethValue || ''} />
              {methodFragment && methodFragment.inputs.length > 0 && (
                <>
                  <h2>Parameters</h2>
                  {methodFragment.inputs.map((input, idx) => (
                    <ParameterWrapper key={idx}>
                      <ParameterTitle>
                        {input.name}({input.type})
                      </ParameterTitle>
                      <Input
                        onChange={(event) => setParam(input.type, input.name, event.target.value)}
                        value={getParam(input.name) || ''}
                      />
                    </ParameterWrapper>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </SubmitTransactionBody>
      <ActionsWrapper>
        <PrimaryButton disabled={selectedMethod === undefined} onClick={onSubmit}>
          Submit Transaction
        </PrimaryButton>
      </ActionsWrapper>
    </SubmitTransactionWrapper>
  )
}
