import { Checkbox, ShinyHeader } from '@unifiprotocol/uikit'
import { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { DepartmentPendingTrxMap } from '../../Hooks/useOfflineContracts'
import { PendingTrxs } from '../../State/PendingTrxs'
import { TransactionSummary } from './Components/TransactionSummary'

const DepartmentPendingTrxsWrapper = styled.div`
  margin-top: 1rem;
`

const DepartmentName = styled.div`
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
`

const NoTransactionsText = styled.div`
  padding: 1rem;
  color: #ffffffaa;
`

const TransactionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0.5rem;
  }
`

const SummaryWrapper = styled.div`
  ${(props) => props.theme.mediaQueries.xs} {
    padding: 0 1rem;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DepartmentPendingTrxs = ({
  departmentName,
  map,
  showHiddenTransactions
}: {
  departmentName: string
  map: DepartmentPendingTrxMap
  showHiddenTransactions: boolean
}) => {
  const pendingTrxsToShow = useMemo(
    () =>
      Object.values(map)
        .map((pendingTrxs) => pendingTrxs)
        .flat()
        .filter((trx) => showHiddenTransactions || !trx.hidden),
    [map, showHiddenTransactions]
  )
  return (
    <DepartmentPendingTrxsWrapper>
      <DepartmentName>{departmentName}</DepartmentName>
      <TransactionsWrapper>
        {pendingTrxsToShow.length > 0 ? (
          pendingTrxsToShow.map((trx) => (
            <TransactionSummary transaction={trx}></TransactionSummary>
          ))
        ) : (
          <NoTransactionsText>No pending transactions in this department</NoTransactionsText>
        )}
      </TransactionsWrapper>
    </DepartmentPendingTrxsWrapper>
  )
}

export const Summary = () => {
  const [showHiddenTransactions, setShowHiddenTransactions] = useState<boolean>(false)
  const pendingTrxs = useRecoilValue(PendingTrxs)

  const loading = useMemo(() => pendingTrxs === undefined, [pendingTrxs])

  return (
    <SummaryWrapper>
      <HeaderWrapper>
        <ShinyHeader>Overview</ShinyHeader>
        <Checkbox
          label="Show hidden transactions"
          checked={showHiddenTransactions}
          onChange={(ev) => setShowHiddenTransactions(ev)}
        />
      </HeaderWrapper>
      {loading ? (
        <p>Loading...</p>
      ) : (
        pendingTrxs &&
        Object.entries(pendingTrxs).map(([deparment, map]) => {
          return (
            map && (
              <DepartmentPendingTrxs
                key={deparment}
                showHiddenTransactions={showHiddenTransactions}
                departmentName={deparment}
                map={map}
              />
            )
          )
        })
      )}
    </SummaryWrapper>
  )
}
