import { TransactionDescription } from 'ethers/lib/utils'
import { useEffect, useMemo, useState } from 'react'
import { MultiSigTransaction } from '../Contracts/MultiSig/Types'
import { useContracts } from './useContracts'

export const useDecodedTransaction = (transaction: MultiSigTransaction) => {
  const { decodeTransaction } = useContracts()
  const [decodedTransaction, setDecodedTransaction] = useState<TransactionDescription>()
  const [decodingTransaction, setDecodingTransaction] = useState<boolean>()

  const argumentsArray = useMemo(
    () =>
      decodedTransaction?.args.map((argValue, i) => {
        const argName = decodedTransaction.functionFragment.inputs[i].name
        return [argName, argValue]
      }) || [],
    [decodedTransaction?.args, decodedTransaction?.functionFragment.inputs]
  )

  const methodName = useMemo(() => decodedTransaction?.name, [decodedTransaction])

  useEffect(() => {
    transaction &&
      decodeTransaction(transaction).then((decodedTransaction) => {
        decodedTransaction && setDecodedTransaction(decodedTransaction)
        setDecodingTransaction(false)
      })
  }, [decodeTransaction, transaction])

  return { decodedTransaction, decodingTransaction, methodName, argumentsArray }
}
