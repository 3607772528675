import { BiCopy, Modal, ModalBody, ModalClose, ModalHeader } from '@unifiprotocol/uikit'
import { ethers } from 'ethers'
import { FC, PropsWithChildren, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ActionText } from '../../../Components/ActionText'
import { CodeBlock } from '../../../Components/CodeBlock'
import { ExternalLink } from '../../../Components/ExternalLink'
import { MultiSigTransaction } from '../../../Contracts/MultiSig/Types'
import { useContracts } from '../../../Hooks/useContracts'
import { useDecodedTransaction } from '../../../Hooks/useDecodedTransaction'

type CopiableButtonProps = PropsWithChildren<{ size?: number; value: string | undefined }>

const StyledModal = styled(Modal)`
  margin-top: 20vh;
  border-radius: 1rem;
`

const StyledModalHeader = styled(ModalHeader)`
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
`

const TransactionInformationContainer = styled.div`
  width: 100%;
  height: fit-content;
  background: ${(p) => p.theme.bgAlt};
  border-radius: 10px;
  padding: 1rem;
`

const TransactionInformationRow = styled.div`
  width: 100%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  position: relative;
  background: ${(p) => p.theme.bgAlt};
`

const TransactionRowBorder = styled.div`
  border: 2px solid ${(p) => p.theme.bg};
  border-radius: 4px;
  width: 90%;
  margin-left: 5%;
`

const TransactionTitle = styled.h2`
  color: ${({ theme }) => theme.primary};
`

const SpaceBetweenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
`

const SpanAvoidOverflow = styled.span`
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`

const CopyTextButton = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.bg};
  background: ${(p) => p.theme.primary};
  &:hover {
    cursor: pointer;
  }
`

const CopiableButton = ({ value }: CopiableButtonProps) => {
  return (
    <CopyTextButton
      onClick={() => {
        value && navigator.clipboard.writeText(value)
      }}
    >
      <BiCopy />
    </CopyTextButton>
  )
}

export const TransactionInfoModal: FC<any> = ({
  transaction,
  close
}: {
  transaction: MultiSigTransaction
  close: Function
}) => {
  const { decodedTransaction, methodName, argumentsArray } = useDecodedTransaction(transaction)

  const { getExplorerAddressEndpoint } = useContracts()

  const contractBlockExplorerEndpoint = useMemo(
    () => transaction?.to && getExplorerAddressEndpoint(transaction.blockchain, transaction.to),
    [transaction?.to, transaction.blockchain, getExplorerAddressEndpoint]
  )

  const [showRawArgs, setShowRawArgs] = useState(false)

  const parsedValue = useMemo(() => {
    try {
      return transaction?.value && ethers.utils.formatEther(transaction?.value)
    } catch (e) {
      return 'Invalid value'
    }
  }, [transaction?.value])

  return (
    <StyledModal>
      <StyledModalHeader>
        <span>Transaction #{transaction.id}</span>
        <ModalClose onClick={() => close()}></ModalClose>
      </StyledModalHeader>
      <ModalBody>
        <TransactionInformationContainer>
          {transaction.commentary && (
            <>
              <TransactionInformationRow>
                <SpaceBetweenWrapper>
                  <span>Commentary:</span>
                  <span>{transaction.commentary}</span>
                </SpaceBetweenWrapper>
              </TransactionInformationRow>
              <TransactionRowBorder />
            </>
          )}
          <TransactionInformationRow>
            <SpaceBetweenWrapper>
              <span>To:</span>
              <SpanAvoidOverflow>
                <ExternalLink href={contractBlockExplorerEndpoint}>{transaction?.to}</ExternalLink>
              </SpanAvoidOverflow>
              <CopiableButton value={transaction?.to} />
            </SpaceBetweenWrapper>
          </TransactionInformationRow>
          <TransactionRowBorder />
          <TransactionInformationRow>
            <SpaceBetweenWrapper>
              <span>Data:</span>
              <SpanAvoidOverflow>{transaction?.data}</SpanAvoidOverflow>
              <CopiableButton value={transaction?.data} />
            </SpaceBetweenWrapper>
          </TransactionInformationRow>
          <TransactionRowBorder />
          <TransactionInformationRow>
            <SpaceBetweenWrapper>
              <span>Value:</span>
              <SpanAvoidOverflow>{parsedValue} ETH</SpanAvoidOverflow>
              <CopiableButton value={transaction?.value} />
            </SpaceBetweenWrapper>
          </TransactionInformationRow>
          <TransactionRowBorder />
          <TransactionInformationRow>
            <SpaceBetweenWrapper>
              <span>Num. Confirmations: {transaction?.numConfirmations}</span>
              <span>Executed: {transaction?.executed ? 'Yes' : 'No'}</span>
            </SpaceBetweenWrapper>
          </TransactionInformationRow>
          <TransactionRowBorder />
          {decodedTransaction ? (
            <>
              <TransactionInformationRow>
                <TransactionTitle>Decoded data</TransactionTitle>
              </TransactionInformationRow>
              <TransactionInformationRow>
                <SpaceBetweenWrapper>
                  <span>Method name:</span>
                  <SpanAvoidOverflow>{methodName}</SpanAvoidOverflow>
                </SpaceBetweenWrapper>
              </TransactionInformationRow>
              {argumentsArray && argumentsArray.length > 0 && (
                <TransactionInformationRow>
                  <TransactionTitle>Arguments</TransactionTitle>
                </TransactionInformationRow>
              )}
              {argumentsArray.map(([key, value]) => (
                <TransactionInformationRow key={key}>
                  <SpaceBetweenWrapper>
                    <span>{key}: </span>
                    {ethers.utils.isAddress(value) ? (
                      <SpanAvoidOverflow>
                        <ExternalLink
                          href={getExplorerAddressEndpoint(
                            transaction.blockchain,
                            ethers.utils.getAddress(value)
                          )}
                        >
                          {String(value)}
                        </ExternalLink>
                      </SpanAvoidOverflow>
                    ) : (
                      <SpanAvoidOverflow>{String(value)}</SpanAvoidOverflow>
                    )}
                  </SpaceBetweenWrapper>
                </TransactionInformationRow>
              ))}
              {argumentsArray && argumentsArray.length > 0 && (
                <>
                  <TransactionInformationRow>
                    <ActionText onClick={() => setShowRawArgs(!showRawArgs)}>
                      {!showRawArgs ? 'Show' : 'Hide'} raw args
                    </ActionText>
                  </TransactionInformationRow>
                  {showRawArgs && (
                    <TransactionInformationRow>
                      <CodeBlock>{Object.fromEntries(argumentsArray)}</CodeBlock>
                    </TransactionInformationRow>
                  )}
                </>
              )}
            </>
          ) : null}
        </TransactionInformationContainer>
      </ModalBody>
    </StyledModal>
  )
}
