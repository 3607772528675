// Import the functions you need from the SDKs you need
import { getFirestore } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC60v5RQTc5u3t0BzMUOGpeqysjEUgBtXw',
  authDomain: 'unifi-337316.firebaseapp.com',
  projectId: 'unifi-337316',
  storageBucket: 'unifi-337316.appspot.com',
  messagingSenderId: '750105313745',
  appId: '1:750105313745:web:a98b24b5076576743920f0',
  measurementId: 'G-2MW2RLJ0H5'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const firestore = getFirestore(app)
