import { FC, useEffect, useMemo } from 'react'
import { Modal, ModalHeader, ModalBody, useModal, ModalClose } from '@unifiprotocol/uikit'
import { Blockchains, getBlockchainConfig } from '@unifiprotocol/core-sdk'
import Config from '../../Config'
import { BlockchainOption } from './Styles'
import { useAuth } from '../../Hooks/useAuth'
import { useRecoilState } from 'recoil'
import { Auth } from '../../State/Auth'
import { getVernacularBlockchain } from '@unifiprotocol/utils'

const AuthComp: FC = () => {
  const { connect } = useAuth()
  const [{ department, ...otherProps }, setAuthState] = useRecoilState(Auth)

  const BLOCKCHAINS_AVAILABLE = useMemo(
    () =>
      Object.keys(Config.contracts[department!] || {})
        .filter((b) => {
          const blockchain = b as Blockchains
          return Object.values(Blockchains).includes(blockchain)
        })
        .map((b) => getBlockchainConfig(b as Blockchains)),
    [department]
  )

  return (
    <Modal>
      <ModalHeader>
        Select a blockchain
        <ModalClose
          onClick={() => setAuthState({ department: undefined, ...otherProps })}
        ></ModalClose>
      </ModalHeader>
      <ModalBody>
        {BLOCKCHAINS_AVAILABLE.map((b) => (
          <BlockchainOption key={b.blockchain} onClick={() => connect(b.blockchain)}>
            <img src={b.logoURI} alt={b.blockchain} />
            {getVernacularBlockchain(b.blockchain)}
          </BlockchainOption>
        ))}
      </ModalBody>
    </Modal>
  )
}
export const DepartmentModalComponent: FC<{
  onSelect: (department: string) => void
  blockchain?: Blockchains
}> = ({ onSelect, blockchain }) => {
  const departments = useMemo(
    () =>
      Object.keys(Config.contracts).filter(
        (dep) => !blockchain || Config.contracts[dep]![blockchain]
      ),
    [blockchain]
  )
  return (
    <Modal>
      <ModalHeader>Select a department</ModalHeader>
      <ModalBody>
        {departments.map((dep) => (
          <BlockchainOption key={dep} onClick={() => onSelect(dep)}>
            {dep}
          </BlockchainOption>
        ))}
      </ModalBody>
    </Modal>
  )
}

export const AuthModal = () => {
  const [{ adapter, department }, setAuthState] = useRecoilState(Auth)

  const [openNetworkSelector, closeNetworkSelector] = useModal({
    component: AuthComp,
    props: {},
    options: { disableBackdropClick: false }
  })

  const [openDepartmentSelector, closeDepartmentSelector] = useModal({
    component: DepartmentModalComponent,
    props: { onSelect: (department: string) => setAuthState({ adapter, department }) },
    options: { disableBackdropClick: false }
  })

  useEffect(() => {
    if (department) {
      if (!adapter) {
        openNetworkSelector()
      } else {
        closeNetworkSelector()
      }
      closeDepartmentSelector()
    } else {
      openDepartmentSelector()
    }
  }, [
    adapter,
    closeNetworkSelector,
    openNetworkSelector,
    department,
    openDepartmentSelector,
    closeDepartmentSelector
  ])

  return <></>
}
