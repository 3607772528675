import { Blockchains } from '@unifiprotocol/core-sdk'
import { abi as ERC20ABI } from '../Contracts/ABI/ERC20.json'
import { abi as MintableERC20ABI } from '../Contracts/ABI/MintableERC20.json'
import { abi as HarmonyStakingStrategyABI } from '../Contracts/ABI/HarmonyStakingStrategy.json'
import {
  bridge,
  bridgeProxy,
  multisig,
  rebalancer,
  darbi,
  unfi,
  up,
  upController,
  upMintDarbi,
  upMintPublic,
  vendor
} from './default'

const defaultContracts = [
  {
    name: 'ERC20',
    type: 'IERC20',
    address: '',
    abi: ERC20ABI
  },
  {
    type: 'Custom',
    name: 'Custom'
  }
]

export type ContractInfo = { name: string; type?: string; address?: string; abi?: any[] }

export const blockchainInfo: {
  [k in Blockchains]?: {
    blockexplorerEndpoints: { [k: string]: (address: string) => string | undefined }
    contracts: ContractInfo[]
  }
} = {
  [Blockchains.BinanceTestnet]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api-testnet.bscscan.com/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://testnet.bscscan.com/address/${address}`
    },
    contracts: [
      {
        name: 'Honest Greens Token',
        type: 'Mintable ERC20',
        address: '0x79EC67BEC9eC6E5Ed56313799ffb737186375d09',
        abi: MintableERC20ABI
      },
      multisig('0xa1281A6C38D9f3fb862Eca1406A0f7FfecDe1212'),
      multisig('0x1FbB085e4e6c867CE7436B307dD0BcaA4F0A4178', 'MultiSig Tech'),
      bridgeProxy('0x9a7aE5779b6b8577c4591CD21482e3A3E6C3d345'),
      bridge('0x9a7aE5779b6b8577c4591CD21482e3A3E6C3d345'),
      ...defaultContracts
    ]
  },
  [Blockchains.Binance]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://bscscan.com/address/${address}`
    },
    contracts: [
      bridgeProxy('0x3e56eC6d02d2dd26d570dA462003b9dAC5Be75c9'),
      bridge('0x3e56eC6d02d2dd26d570dA462003b9dAC5Be75c9'),
      multisig('0x92a661d868Aa1c8a3072765B462480029DcD8582'),
      unfi('0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B'),
      upController('0xf199c50bA225cb44e06A74b77C86525A72DF9E95'),
      up('0x36F20660b9947929Ab3edd8727B5Af60260333A7'),
      rebalancer('0x9baff15dF8740b46f73851419D95e9E39d7cc51a'),
      darbi('0x6FF5d45FC272732722F27702fC36E57f0Aa203C5'),
      upMintDarbi('0x90817bCcafAb6D5be9E0198252Ba70C542a91bac '),
      upMintPublic('0xaf992F205141D6d3cf8Df1E5e3eeB31cCC364E44'),
      vendor('0xbcF968a9Cf33Fa309c3810afAf13c34De1a45D64'),
      ...defaultContracts
    ]
  },
  [Blockchains.Iotex]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api.iotexscout.io/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://iotexscout.io/address/${address}`
    },
    contracts: [
      bridgeProxy('0x30182faf963B4f5E54A8Ba48c98529fd9D45e0FD'),
      bridge('0x30182faf963B4f5E54A8Ba48c98529fd9D45e0FD'),
      multisig('0x97Ac65A70A804B6c35b408d9bD7B18fc52F4aDF0'),
      unfi('0xD2ECEDa377DAe9dAF952c18786be736bEC9312cC'),
      rebalancer('0xbe0A4b72cA7E2E808D3D8f019Cf04b35F9957Cc8'),
      ...defaultContracts
    ]
  },
  [Blockchains.FTM]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api.ftmscan.com/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://ftmscan.com/address/${address}`
    },
    contracts: [
      bridgeProxy('0xda23E134987E10298FEEDDfc51d09Aeb91D13808'),
      bridge('0xda23E134987E10298FEEDDfc51d09Aeb91D13808'),
      multisig('0x9eE36f0f6183595458252FB03dF799f4FE45C019'),
      unfi('0x3824d0c574641cc8cb9253e6a84fe26e1e7a349f'),
      ...defaultContracts
    ]
  },
  [Blockchains.Ethereum]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api.etherscan.io/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://etherscan.io/address/${address}`
    },
    contracts: [
      bridgeProxy('0x67d44ce5Ea238a2CFAE1123c29bc37d2a9a94A23'),
      bridge('0x67d44ce5Ea238a2CFAE1123c29bc37d2a9a94A23'),
      multisig('0x59026F4168fb26a0bFc8C5155C9B59d0DF2fD001'),
      unfi('0x441761326490cACF7aF299725B6292597EE822c2'),
      ...defaultContracts
    ]
  },
  [Blockchains.Ontology]: {
    blockexplorerEndpoints: {},
    contracts: [
      bridgeProxy('0x9f64D3651CEaE6fcC860406E9EC538Ff9FfCEAFF'),
      bridge('0x9f64D3651CEaE6fcC860406E9EC538Ff9FfCEAFF'),
      multisig('0xAB63B0f4e1cDAad7c4849f8E9AEdcD40601C15d7'),
      unfi('0xb510ac30c04c86Fc2FcFCc2012A415d2cEd8e629'),
      ...defaultContracts
    ]
  },
  [Blockchains.Harmony]: {
    blockexplorerEndpoints: {},
    contracts: [
      bridgeProxy('0xf8086F90d4b05Cfc978108dF46B5BD39E30EbFF0'),
      bridge('0xf8086F90d4b05Cfc978108dF46B5BD39E30EbFF0'),
      multisig('0x814D299c9081085C6b99208f1387738EeD3D638F'),
      unfi('0xEe7207c782d6937BE63E38FCF902fF59E5498386'),
      upController('0xC28A5F528603943f7FD9B594CA77442F42d395F6'),
      up('0x3227ADF1c9fb03Bb7f83b686f44f10CFf498a84c'),
      rebalancer('0xE3079bFBB8724DC7Aed40878a4C82D790320A929'),
      darbi('0x1EE9D7Cb8f5023067E13b754df6D7c86D8C60E3d'),
      upMintDarbi('0x1985028AD8b129CFA6DD5B4C61407c3F787A0038'),
      upMintPublic('0x45511d50873aeDc18d6c5aB759a2c47275d86098'),
      vendor('0x8A069ffE194Ba351122a4b9Ee570345e825ec721'),
      {
        name: 'UPv2 ONE Strategy',
        type: 'UPv2 Strategy',
        address: '0x31EdcA248fCF87759A2Fb9137f8B0b8615E81adb',
        abi: HarmonyStakingStrategyABI
      },
      ...defaultContracts
    ]
  },
  [Blockchains.BTTC]: {
    blockexplorerEndpoints: {
      abi: (address) =>
        `https://api.bttcscan.com/api?module=contract&action=getabi&address=${address}&format=json`,
      address: (address) => `https://bttcscan.com/address/${address}`
    },
    contracts: [
      unfi('0x4d6a69c8700393cbd161a1799789345cc393a441'),
      bridgeProxy('0x20471F64D5ADD33c74d1180896e825Ad809D5109'),
      bridge('0x20471F64D5ADD33c74d1180896e825Ad809D5109'),
      ...defaultContracts
    ]
  },
  [Blockchains.Polygon]: {
    blockexplorerEndpoints: {
      abi: (address: string) =>
        `https://api.polygonscan.com/api?module=contract&action=getabi&address=${address}`,
      address: (address: string) => `https://polygonscan.com/address/${address}`
    },
    contracts: [
      bridgeProxy('0xE9ADc264C151472B51F7E414E16501Db4667c484'),
      bridge('0xE9ADc264C151472B51F7E414E16501Db4667c484'),
      multisig('0xbCE8163f572cECF5eAA939a75Ba9537501d30E0b'),
      multisig('0xc9C2c2Ddc24d11324A9aA27d97F474EE2Dad017F'),
      unfi('0xed6072Ef5032484c2CC5f76630310e4BD36e62d2'),
      rebalancer('0x1d68dc6c4b6794881b05f4d697fc3138c113185f'),
      ...defaultContracts
    ]
  },
  [Blockchains.Avalanche]: {
    blockexplorerEndpoints: {},
    contracts: [
      bridgeProxy('0x72786B8a580198Ee3C82Ba0e54E8061b026B53ff'),
      bridge('0x72786B8a580198Ee3C82Ba0e54E8061b026B53ff'),
      multisig('0xddce1C777D4A9128c19d2373f5B7015797de8641'),
      unfi('0x42A99bB49b54811A95A36981Cb03d230A0Aef67B'),
      ...defaultContracts
    ]
  },
  [Blockchains.EthereumGoerli]: {
    blockexplorerEndpoints: {
      abi: (address: string) =>
        `https://api-goerli.etherscan.io/api?module=contract&action=getabi&address=${address}`,
      address: (address: string) => `https://goerli.etherscan.io/address/${address}`
    },
    contracts: [
      {
        name: 'Honest Greens Token',
        type: 'Mintable ERC20',
        address: '0x46215bEf664C8AE6Ed9244335fa3f861A62ed006',
        abi: MintableERC20ABI
      },
      bridgeProxy('0x6d739E5f02E982e4553033253f6d9F2F532CD332'),
      multisig('0x20C1c9c3DF2BFC0fFeA4c3381eB85E3DB10d717f'),
      bridge('0x6d739E5f02E982e4553033253f6d9F2F532CD332'),
      rebalancer('0x606F4f57859121edc8e7298c15185C368cD9A7Da'),
      darbi('0xb6f988ddf7f3ef18546fd277b8038acb402faa77'),
      ...defaultContracts
    ]
  }
}
