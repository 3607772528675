import { Blockchains } from '@unifiprotocol/core-sdk'
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { MultiSigTransaction } from '../Contracts/MultiSig/Types'
import { firestore } from '../Services/Firebase'

export const useTransactionsMetadata = () => {
  const hideTransaction = useCallback(
    (blockchain: Blockchains, multisignAddress: string, transactionId: number) => {
      const docRef = doc(
        firestore,
        `/hidden-transactions/${blockchain}/${multisignAddress}/${transactionId}`
      )

      return setDoc(
        docRef,
        {
          hidden: true
        },
        { merge: true }
      )
    },
    []
  )

  const addCommentTransaction = useCallback(
    (
      blockchain: Blockchains,
      multisignAddress: string,
      transactionId: number,
      commentary: string
    ) => {
      const docRef = doc(
        firestore,
        `/hidden-transactions/${blockchain}/${multisignAddress}/${transactionId}`
      )

      return setDoc(
        docRef,
        {
          commentary
        },
        {
          merge: true
        }
      )
    },
    []
  )

  const unhideTransaction = useCallback(
    (blockchain: Blockchains, multisignAddress: string, transactionId: number) => {
      const docRef = doc(
        firestore,
        `/hidden-transactions/${blockchain}/${multisignAddress}/${transactionId}`
      )
      return deleteDoc(docRef)
    },
    []
  )

  const getHideableTransactions = useCallback(
    async (
      blockchain: Blockchains,
      multisignAddress: string,
      transactions: MultiSigTransaction[]
    ) => {
      return Promise.all(
        transactions.map(async (transaction) => {
          return getDoc(
            doc(
              firestore,
              `/hidden-transactions/${blockchain}/${multisignAddress}/${transaction.id}`
            )
          )
            .then((e) => (e.exists() ? { ...transaction, ...e.data() } : transaction))
            .catch(() => transaction)
        })
      )
    },
    []
  )

  const isTransactionHidden = useCallback(
    (blockchain: Blockchains, multisigAddress: string, trxId: number) => {
      return getDoc(
        doc(firestore, `/hidden-transactions/${blockchain}/${multisigAddress}/${trxId}`)
      ).then((e) => e.exists() && e.data().hidden)
    },
    []
  )

  return {
    hideTransaction,
    unhideTransaction,
    addCommentTransaction,
    getHideableTransactions,
    isTransactionHidden
  }
}
