import { ContractUseCase, IAdapter } from '@unifiprotocol/core-sdk'
import { BN } from '@unifiprotocol/utils'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

export class GetTransactions extends ContractUseCase<
  MultiSigContractMethods,
  [],
  MultiSigTransaction[]
> {
  constructor({ contractAddress }: TMultiSigParams) {
    super(contractAddress, MultiSigContractMethods.getTransactions, [], false)
  }

  execute(adapter: IAdapter) {
    return super.execute(adapter).then((res) => {
      const rawValue = res.value as unknown as string[]
      const value = rawValue.map((chunk, i) => {
        const chunkArr = chunk.split(',')
        return {
          id: i,
          to: chunkArr[0],
          value: chunkArr[1],
          data: chunkArr[2],
          executed: chunkArr[3] === 'true',
          numConfirmations: BN(chunkArr[4]).toNumber(),
          blockchain: adapter.blockchainConfig.blockchain
        }
      })

      value.reverse()

      return {
        ...res,
        value
      }
    })
  }
}
