import { abi as ERC20ABI } from '../Contracts/ABI/ERC20.json'
import { abi as uBridgeProxyABI } from '../Contracts/ABI/uBridgeProxy.json'
import { abi as uBridgeABI } from '../Contracts/ABI/uBridge.json'
import { abi as DarbiABI } from '../Contracts/ABI/Darbi.json'
import { abi as UpMintDarbiABI } from '../Contracts/ABI/UPMintDarbi.json'
import { abi as UpMintPublicABI } from '../Contracts/ABI/UPMintPublic.json'
import { abi as VendorABI } from '../Contracts/ABI/Vendor.json'
import { abi as MultiSigABI } from '../Contracts/ABI/MultiSigWallet.json'
import { abi as UPControllerABI } from '../Contracts/ABI/UPController.json'
import { abi as UPABI } from '../Contracts/ABI/UP.json'
import { abi as RebalancerABI } from '../Contracts/ABI/Rebalancer.json'

export const bridgeProxy = (address: string) => ({
  name: 'uBridge Proxy',
  type: 'Proxy',
  address,
  abi: uBridgeProxyABI
})

export const bridge = (address: string) => ({
  name: 'uBridge',
  type: 'uBridge',
  address,
  abi: uBridgeABI
})

export const upController = (address: string) => ({
  name: 'UPController',
  type: 'UPController',
  address,
  abi: UPControllerABI
})

export const up = (address: string) => ({
  name: 'UP',
  type: 'UP',
  address,
  abi: UPABI
})

export const rebalancer = (address: string) => ({
  name: 'Rebalancer',
  type: 'Rebalancer',
  address,
  abi: RebalancerABI
})

export const unfi = (address: string) => ({
  name: 'UNFI',
  type: 'IERC20',
  address,
  abi: ERC20ABI
})

export const multisig = (address: string, name = 'MultiSig Admin') => ({
  name,
  type: 'MultiSig',
  address,
  abi: MultiSigABI
})

export const darbi = (address: string) => ({
  name: 'Darbi',
  type: 'Darbi',
  address,
  abi: DarbiABI
})

export const upMintDarbi = (address: string) => ({
  name: 'UpMintDarbi',
  type: 'UpMintDarbi',
  address,
  abi: UpMintDarbiABI
})

export const upMintPublic = (address: string) => ({
  name: 'UpMintPublic',
  type: 'UpMintPublic',
  address,
  abi: UpMintPublicABI
})

export const vendor = (address: string) => ({
  name: 'Vendor',
  type: 'Vendor',
  address,
  abi: VendorABI
})
