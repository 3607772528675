import { IAdapter } from '@unifiprotocol/core-sdk'
import { atom } from 'recoil'

type TAuth = {
  adapter?: IAdapter | undefined
  department?: string | undefined
}

export const Auth = atom<TAuth>({
  key: 'authState',
  dangerouslyAllowMutability: true,
  default: { adapter: undefined, department: undefined }
})
