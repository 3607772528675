import React, { useCallback, useState } from 'react'
import { Input, PrimaryButton, SecondaryButton } from '@unifiprotocol/uikit'
import { ActionsWrapper } from '../../SubmitTransaction/Styles'
import { useMultiSig } from '../../../Hooks/useMultiSig'
import { BN } from '@unifiprotocol/utils'
import { useRecoilValue } from 'recoil'
import { MultiSig } from '../../../State/MultiSig'

export const ConfirmationsNumber = () => {
  const { numConfirmations } = useRecoilValue(MultiSig)
  const [confirmationsNumberValue, setConfirmationsNumberValue] = useState('')
  const { setConfirmationsNumber } = useMultiSig()

  const onUpdateConfirmationsNumberClick = useCallback(() => {
    if (BN(confirmationsNumberValue).isNaN() || BN(confirmationsNumberValue).lt(0)) {
      throw new Error('Confirmations number must be a non-negative number.')
    }
    setConfirmationsNumber(BN(confirmationsNumberValue).toNumber())
  }, [confirmationsNumberValue, setConfirmationsNumber])

  return (
    <>
      <div>Current value: {numConfirmations}</div>
      <Input onChange={(evt) => setConfirmationsNumberValue(evt.currentTarget.value)} />
      <ActionsWrapper>
        <SecondaryButton onClick={() => setConfirmationsNumberValue('')}>Clear</SecondaryButton>
        <PrimaryButton onClick={onUpdateConfirmationsNumberClick}>
          Update Confirmations Number
        </PrimaryButton>
      </ActionsWrapper>
    </>
  )
}
