import { ContractUseCase, GenericUseCase, IAdapter } from '@unifiprotocol/core-sdk'
import { toHex } from '@unifiprotocol/utils'
import { ethers } from 'ethers'
import { MultiSigContractMethods, MultiSigTransaction, TMultiSigParams } from './Types'

type SubmitTransactionParams = {
  to: string
  value: string
  useCase: GenericUseCase
  abi: ethers.utils.Interface
}

export class SubmitTransaction extends ContractUseCase<
  MultiSigContractMethods,
  SubmitTransactionParams,
  MultiSigTransaction[]
> {
  constructor({ contractAddress, params }: TMultiSigParams<SubmitTransactionParams>) {
    super(contractAddress, MultiSigContractMethods.submitTransaction, params!, true)
  }

  getArgs() {
    const data = this.params.abi.encodeFunctionData(
      this.params.useCase.method,
      this.params.useCase.getArgs()
    )
    return [this.params.to, toHex(this.params.value), data]
  }

  execute(adapter: IAdapter) {
    adapter.initializeContract(this.params.to, this.params.abi)
    return super.execute(adapter)
  }
}
