import { useMemo } from 'react'
import styled from 'styled-components'

const CodeBlockWrapper = styled.div`
  display: flex;
  background: ${(p) => p.theme.backgroundAlt2};
`

export const CodeBlock = ({ children }: { children: string | object }) => {
  const text = useMemo(
    () => (typeof children === 'object' ? JSON.stringify(children, null, 4) : children),
    [children]
  )
  return <CodeBlockWrapper>{text}</CodeBlockWrapper>
}
