import {
  DangerButton,
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  PrimaryButton
} from '@unifiprotocol/uikit'
import { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import Config from '../../../Config'
import { MultiSigTransaction } from '../../../Contracts/MultiSig/Types'
import { useTransactionsMetadata } from '../../../Hooks/useTransactionsMetadata'
import { Auth } from '../../../State/Auth'

type HideTransactionModalProps = PropsWithChildren<{
  transaction: MultiSigTransaction
  close: Function
}>

const StyledModal = styled(Modal)`
  margin-top: 20vh !important;
`

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > * {
    margin-top: 1rem;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

export const HideTransactionModal = ({ transaction, close }: HideTransactionModalProps) => {
  const { hideTransaction, unhideTransaction } = useTransactionsMetadata()
  const { adapter, department } = useRecoilValue(Auth)

  const blockchain = adapter!.blockchainConfig.blockchain
  const multisignAddress = Config.contracts[department!]![blockchain]!
  return (
    <StyledModal>
      <ModalHeader>
        <span>
          {transaction.hidden ? 'Unhide' : 'Hide'} transaction #{transaction.id}
        </span>
        <ModalClose onClick={() => close()}></ModalClose>
      </ModalHeader>
      <StyledModalBody>
        <ButtonsWrapper>
          <DangerButton onClick={() => close()}>Cancel</DangerButton>
          {transaction.hidden ? (
            <PrimaryButton
              onClick={() =>
                unhideTransaction(blockchain, multisignAddress, transaction.id).finally(() =>
                  close()
                )
              }
            >
              Unhide
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() =>
                hideTransaction(blockchain, multisignAddress, transaction.id).finally(() => close())
              }
            >
              Hide
            </PrimaryButton>
          )}
        </ButtonsWrapper>
      </StyledModalBody>
    </StyledModal>
  )
}
